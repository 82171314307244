import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useSWR from "swr";
import { fetcher } from "../../utils/Functions";
import { AWS_URL, staticOptions } from "../../utils/Constants";
import Table from "../../components/Commons/Table";
import LinearLoading from "../../components/Commons/LinearLoading";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { IconButton, Tooltip } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import TimeField from "../../components/Commons/TimeField";

// import LibraryAddIcon from "@material-ui/icons/LibraryAdd";

interface ContactProps {}

const Contact: React.FC<ContactProps> = ({}) => {
  const { data, error } = useSWR(`/invoice`, fetcher, staticOptions);
  const [openError, setOpenError] = useState(false);

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;
    data?.result.map((item) => {
      item.pdfUrl = Boolean(item.pdfUrl) ? getLinkIcon(item.pdfUrl) : null;
      item.invoiceNumber = item.data.invoiceNumber;
      item.createdAt = timeField(item.createdAt);
    });
  }, [data]);

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    console.log(error.info);
    setOpenError(true);
  }, [error]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  function getLinkIcon(url: string) {
    return (
      <Tooltip title="Open File" arrow>
        <IconButton
          aria-label="open"
          onClick={() => window.open(`${url}`, "_blank")}
        >
          <LaunchIcon color="action" />
        </IconButton>
      </Tooltip>
    );
  }
  function timeField(date: string) {
    return <TimeField date={date} showTime={true} />;
  }

  return (
    <div>
      {/* <Link to="/contact/addContact">
        <div
          className={clsx(
            "addMemberButton",
            "buttonEffectShadow",
            "activeButton"
          )}
          style={{ width: "140px" }}
        >
          <LibraryAddIcon />
          Add Contact
        </div>
      </Link> */}
      {!!!data ? (
        <LinearLoading />
      ) : (
        <Table
          data={data?.result}
          uniqueId={"_id"}
          // enableEdit={true}
          // enableDelete={true}
          //   navTo={"contact/editContact"}
          //   apiEndpoint={"contact"}
          dataOrder={[
            "invoiceNumber",
            "paymentId",
            "userEmail",
            "pdfUrl",
            "createdAt",
          ]}
        />
      )}
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while fetching Contact.
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default Contact;
