import React, { useEffect, useState } from "react";
import MultiSelect from "react-select";
import makeAnimated from "react-select/animated";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormGroup,
  FormControlLabel,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "../../assets/style/Notes.css";
import {
  AWS_URL,
  OPTIONALS,
  PCS,
  staticOptions,
  HSN,
} from "../../utils/Constants";
import { errorToast, fetcher } from "../../utils/Functions";
import useSWR from "swr";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import QuillTextEditor from "../../components/Commons/QuillTextEditor";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import FileUploader from "../../components/Commons/FileUploader";
import { Toaster } from "react-hot-toast";

interface AddEditSubscriptionProps {}

const animatedComponents = makeAnimated();

interface invoiceData {
  hsn: null | number | string;
  valuePercentage: null | number | string;
  cgstRate: null | number | string;
  sgstRate: null | number | string;
  igstRate: null | number | string;
  description: string;
}

const AddEditSubscription: React.FC<AddEditSubscriptionProps> = ({}) => {
  const [notesLibrary, setNotesLibrary] = useState([]);
  const [videosLibrary, setVideosLibrary] = useState([]);
  const [mainsTS, setMainsTS] = useState([]);
  const [selectedNLOption, setSelectedNLOption] = useState<any>(null);
  const [selectedVLOption, setSelectedVLOption] = useState<any>(null);
  const [selectedTSOption, setSelectedTSOption] = useState<any>(null);
  const [subscriptionData, setSubscriptionData] = useState<any>({});
  const [openError, setOpenError] = useState(false);
  const [selectedNotesLibrary, setSelectedNotesLibrary] = useState(null);
  const [selectedVideosLibrary, setSelectedVideosLibrary] = useState(null);
  const [selectedMainsTS, setSelectedMainsTS] = useState(null);
  const [details, setDetails] = useState<string>("");
  const [subscribedDetails, setSubscribedDetails] = useState<string>("");
  const [discountInfo, setDiscountInfo] = useState<string>("");
  const [selectedDates, setSelectedDates] = useState<{
    startDate: Date | string | null;
    endDate: Date | string | null;
  }>({ startDate: null, endDate: null });
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState(null);
  const [fileUrls, setFileUrls] = useState({});
  const [invoiceData, setInvoiceData] = useState<[invoiceData]>([
    {
      hsn: null,
      valuePercentage: null,
      cgstRate: null,
      sgstRate: null,
      igstRate: null,
      description: "",
    },
  ]);

  let { id } = useParams();
  const navigate = useNavigate();

  //For GET Request to get all the notes libraries
  const { data: notesLibraryData, error: notesLibraryError } = useSWR(
    `/notesLibrary`,
    fetcher,
    staticOptions
  );
  const { data: videosLibraryData, error: videosLibraryError } = useSWR(
    `/videosLibrary`,
    fetcher,
    staticOptions
  );

  const { data: subscriptionsData, error: subscriptionsError } = useSWR(
    `/subscriptionType`,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(notesLibraryData) || Boolean(notesLibraryData?.error)) return;
    const dropdownData: any = [];
    notesLibraryData?.result?.forEach((item: any) => {
      let option = { value: item._id, label: item.name };
      dropdownData.push(option);
    });
    setNotesLibrary(dropdownData);
  }, [notesLibraryData]);

  useEffect(() => {
    if (!Boolean(videosLibraryData) || Boolean(videosLibraryData?.error))
      return;
    const dropdownData: any = [];
    videosLibraryData?.result?.forEach((item) => {
      let option = { value: item._id, label: item.name };
      dropdownData.push(option);
    });
    setVideosLibrary(dropdownData);
  }, [videosLibraryData]);

  useEffect(() => {
    if (!Boolean(subscriptionsData) || Boolean(subscriptionsData?.error))
      return;
    const dropdownData: any = [];
    subscriptionsData?.result?.forEach((item) => {
      let option = { value: item._id, label: item.name };
      dropdownData.push(option);
    });
    setSubscriptions(dropdownData);
  }, [subscriptionsData]);

  useEffect(() => {
    if (!Boolean(subscriptionsError) || !Boolean(subscriptionsError?.status))
      return;
    console.log(subscriptionsError.info);
    setOpenError(true);
  }, [subscriptionsError]);

  useEffect(() => {
    if (!Boolean(notesLibraryError) || !Boolean(notesLibraryError?.status))
      return;
    console.log(notesLibraryError.info);
    setOpenError(true);
  }, [notesLibraryError]);

  useEffect(() => {
    if (!Boolean(videosLibraryError) || !Boolean(videosLibraryError?.status))
      return;
    console.log(videosLibraryError.info);
    setOpenError(true);
  }, [videosLibraryError]);

  //For GET Request to get all the Mains Test Series
  const { data: mainsTSData, error: mainsTSError } = useSWR(
    `/mainsTS`,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(mainsTSData) || Boolean(mainsTSData?.error)) return;
    const dropdownData: any = [];
    mainsTSData?.result?.mainsTSList?.forEach((item) => {
      let option = { value: item._id, label: item.name };
      dropdownData.push(option);
    });
    setMainsTS(dropdownData);
  }, [mainsTSData]);

  useEffect(() => {
    if (!Boolean(mainsTSError) || !Boolean(mainsTSError?.status)) return;
    console.log(mainsTSError.info);
    setOpenError(true);
  }, [mainsTSError]);

  // For POST Request
  const { data: postData, error: postError } = useSWR(
    !Boolean(id) ? [`/subscriptionType`, `post`, subscriptionData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(postData) || Boolean(postData?.error)) return;
    navigate("..", { replace: true });
  }, [postData]);

  useEffect(() => {
    if (!Boolean(postError) || !Boolean(postError?.status)) return;
    console.log(postError.info);
    setOpenError(true);
  }, [postError]);

  //for GET Request to get notes details. Depends on id parameter
  const { data: getData, error: getError } = useSWR(
    Boolean(id)
      ? `/subscriptionType/${id}?notesLibrary=true&mainsTestSeries=true&videosLibrary=true`
      : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(getError) || !Boolean(getError?.status)) return;
    console.log(getError.info);
    setOpenError(true);
  }, [getError]);

  useEffect(() => {
    if (!Boolean(getData) || Boolean(getData?.error)) return;
    setSelectedDates({
      startDate: Boolean(getData?.result.startDate)
        ? new Date(getData?.result.startDate)
        : null,
      endDate: Boolean(getData?.result.endDate)
        ? new Date(getData?.result.endDate)
        : null,
    });
    let modifiedNotesLibrary: any = [],
      modifiedTestSeries: any = [],
      modifiedVideosLibrary: any = [],
      modifiedSubscriptions: any = [];

    getData?.result?.notesLibrary?.forEach((item) => {
      const notesLibrary = { value: item._id, label: item.name };
      modifiedNotesLibrary.push(notesLibrary);
    });
    if (modifiedNotesLibrary.length) {
      setSelectedNLOption(modifiedNotesLibrary);
      setSelectedNotesLibrary(modifiedNotesLibrary);
    }
    getData?.result?.videosLibrary?.forEach((item) => {
      const videosLibrary = { value: item._id, label: item.name };
      modifiedVideosLibrary.push(videosLibrary);
    });
    if (modifiedVideosLibrary.length) {
      setSelectedVLOption(modifiedVideosLibrary);
      setSelectedVideosLibrary(modifiedVideosLibrary);
    }
    getData?.result?.testSeries?.forEach((item) => {
      const testSeries = { value: item._id, label: item.name };
      modifiedTestSeries.push(testSeries);
    });
    if (modifiedTestSeries.length) {
      setSelectedMainsTS(modifiedTestSeries);
      setSelectedTSOption(modifiedTestSeries);
    }

    if (subscriptions?.length) {
      getData?.result?.discountSubs?.forEach((item) => {
        const subscriptionList = subscriptions.filter(
          (obj: any) => obj.value == item
        );
        modifiedSubscriptions.push(subscriptionList?.[0]);
      });
    }
    if (modifiedSubscriptions.length) {
      setSelectedOption(modifiedSubscriptions);
      setSelectedSubscriptions(modifiedSubscriptions);
    }
    setInvoiceData(
      getData?.result?.invoiceData || [
        {
          hsn: null,
          valuePercentage: null,
          cgstRate: null,
          sgstRate: null,
          igstRate: null,
        },
      ]
    );
  }, [getData, subscriptions]);

  //For PUT Request. Depends on the id param
  const { data: putData, error: putError } = useSWR(
    Boolean(id) ? [`/subscriptionType/${id}`, `put`, subscriptionData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(putError) || !Boolean(putError?.status)) return;
    console.log(putError.info);
    setOpenError(true);
  }, [putError]);

  useEffect(() => {
    if (!Boolean(putData) || Boolean(putData?.error)) return;
    navigate("../..", { replace: true });
  }, [putData]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const setTextEditorData = (data: string) => {
    setDetails(data);
  };

  const setSubscribedTextEditorData = (data: string) => {
    setSubscribedDetails(data);
  };

  const setDiscountInfoTextEditorData = (data: string) => {
    setDiscountInfo(data);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: getData?.result?.name ?? "",
          description: getData?.result?.description ?? "",
          category: getData?.result?.category ?? "",
          type: getData?.result?.type ?? "",
          section: getData?.result?.section ?? "",
          optional: getData?.result?.optional ?? "",
          pcs: getData?.result?.pcs ?? "",
          instaMojoType: getData?.result?.instaMojoType ?? "",
          year: getData?.result?.year ?? "",
          isVisible: getData?.result?.isVisible ?? false,
          isActive: getData?.result?.isActive ?? false,
          showEnquiry: getData?.result?.showEnquiry ?? false,
          isDelivery: getData?.result?.isDelivery ?? false,
          isPopular: getData?.result?.isPopular ?? false,
          subscribeButton: getData?.result?.subscribeButton ?? true,
          price: getData?.result?.price ?? 0,
          strikePrice: getData?.result?.strikePrice ?? 0,
          existingUserPrice: getData?.result?.existingUserPrice ?? 0,
          deliverySubId: getData?.result?.deliverySubId ?? "",
          notesLibrary: [],
          testSeries: [],
          videosLibrary: [],
          discountSubs: [],
          prelimsTestSeriesIdsCommaSeparated:
            getData?.result?.prelimsTestSeriesIdsCommaSeparated ?? "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required("Subscription Name is required"),
          description: Yup.string().required("Description is required"),
          section: Yup.string().required("Section is required"),
        })}
        onSubmit={(values, actions) => {
          let notesLibrary: any = [],
            testSeries: any = [],
            videosLibrary: any = [],
            discountSubs: any = [];

          selectedNLOption?.forEach((item) => {
            notesLibrary.push(item.value);
          });
          selectedVLOption?.forEach((item) => {
            videosLibrary.push(item.value);
          });
          selectedTSOption?.forEach((item) => {
            testSeries.push(item.value);
          });
          selectedOption?.forEach((item) => {
            discountSubs.push(item.value);
          });
          if (invoiceData[0]?.hsn) {
            let value = 0;
            for (let item of invoiceData) {
              value += parseInt(item.valuePercentage as string) || 0;
              if (
                typeof parseInt(item.valuePercentage as string) !== "number" ||
                typeof parseInt(item.cgstRate as string) !== "number" ||
                typeof parseInt(item.sgstRate as string) !== "number" ||
                typeof parseInt(item.igstRate as string) !== "number"
              ) {
                errorToast("Enter all Invoice values");
                return;
              }
            }
            if (value != 100) {
              errorToast("Sum of valuePercentage is not 100");
              return;
            }
          }
          setSubscriptionData({
            ...values,
            ...selectedDates,
            notesLibrary,
            testSeries,
            videosLibrary,
            discountSubs,
            ...(Boolean(details) && { details }),
            ...(Boolean(subscribedDetails) && { subscribedDetails }),
            ...(Boolean(discountInfo) && { discountInfo }),
            ...fileUrls,
            ...(invoiceData[0]?.hsn && { invoiceData }),
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              backgroundColor: "white",
              padding: "30px",
              borderRadius: "15px",
            }}
          >
            <Box>
              <Typography
                color="textPrimary"
                variant="h4"
                style={{ marginBottom: "20px" }}
              >
                {id ? "Edit Subscription:" : "Create a new Subscription:"}
              </Typography>
            </Box>
            <div className="form-container">
              <TextField
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Subscription Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
                required
              />
              <TextField
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                label="Description"
                multiline
                rows={2}
                rowsMax={4}
                margin="normal"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                value={values.description}
                required
              />
              <TextField
                label="Category"
                margin="normal"
                name="category"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.category}
                variant="outlined"
              />
              <TextField
                label="PrelimsTestSeries - Coma Seperated"
                margin="normal"
                name="prelimsTestSeriesIdsCommaSeparated"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.prelimsTestSeriesIdsCommaSeparated}
                variant="outlined"
              />
              <Box style={{ marginTop: "20px", cursor: "pointer", zIndex: 11 }}>
                {Boolean(selectedNotesLibrary) && (
                  <MultiSelect
                    options={notesLibrary}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Notes Libraries"
                    components={animatedComponents}
                    onChange={setSelectedNLOption}
                    defaultValue={selectedNotesLibrary}
                  />
                )}
                {!Boolean(selectedNotesLibrary) && (
                  <MultiSelect
                    options={notesLibrary}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Notes Libraries"
                    components={animatedComponents}
                    onChange={setSelectedNLOption}
                  />
                )}
              </Box>
              <Box style={{ marginTop: "20px", cursor: "pointer", zIndex: 10 }}>
                {Boolean(selectedVideosLibrary) && (
                  <MultiSelect
                    options={videosLibrary}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Videos Libraries"
                    components={animatedComponents}
                    onChange={setSelectedVLOption}
                    defaultValue={selectedVideosLibrary}
                  />
                )}
                {!Boolean(selectedVideosLibrary) && (
                  <MultiSelect
                    options={videosLibrary}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Videos Libraries"
                    components={animatedComponents}
                    onChange={setSelectedVLOption}
                  />
                )}
              </Box>
              <Box style={{ marginTop: "20px", cursor: "pointer", zIndex: 9 }}>
                {Boolean(selectedMainsTS) && (
                  <MultiSelect
                    options={mainsTS}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Test Series"
                    components={animatedComponents}
                    onChange={setSelectedTSOption}
                    defaultValue={selectedMainsTS}
                  />
                )}
                {!Boolean(selectedMainsTS) && (
                  <MultiSelect
                    options={mainsTS}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Test Series"
                    components={animatedComponents}
                    onChange={setSelectedTSOption}
                  />
                )}
              </Box>
              <TextField
                label="Delivery Subscription Id"
                margin="normal"
                name="deliverySubId"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.deliverySubId}
                variant="outlined"
              />
              <Box style={{ marginTop: "30px" }}>
                <Grid container spacing={3}>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      className="dropdown-form"
                      required
                    >
                      <InputLabel>Section</InputLabel>
                      <Select
                        onChange={handleChange}
                        label="Section"
                        name="section"
                        value={values.section}
                      >
                        <MenuItem value="notes">Notes</MenuItem>
                        <MenuItem value="tests">Tests</MenuItem>
                        <MenuItem value="combos">Combos</MenuItem>
                        <MenuItem value="videos">Videos</MenuItem>
                        <MenuItem value="offline">Offline</MenuItem>
                        <MenuItem value="delivery">Delivery</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" className="dropdown-form">
                      <InputLabel>Type</InputLabel>
                      <Select
                        onChange={handleChange}
                        label="Type"
                        name="type"
                        value={values.type}
                      >
                        <MenuItem value="prelims">Prelims</MenuItem>
                        <MenuItem value="mains">Mains</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" className="dropdown-form">
                      <InputLabel>Optional</InputLabel>
                      <Select
                        onChange={handleChange}
                        label="Optional"
                        name="optional"
                        value={values.optional}
                      >
                        {OPTIONALS.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" className="dropdown-form">
                      <InputLabel>PCS</InputLabel>
                      <Select
                        onChange={handleChange}
                        label="Pcs"
                        name="pcs"
                        value={values.pcs}
                      >
                        {PCS.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" className="dropdown-form">
                      <InputLabel>InstaMojo</InputLabel>
                      <Select
                        onChange={handleChange}
                        label="InstaMojo"
                        name="instaMojoType"
                        value={values.instaMojoType}
                      >
                        {PCS.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" className="dropdown-form">
                      <InputLabel>Year</InputLabel>
                      <Select
                        onChange={handleChange}
                        label="Year"
                        name="year"
                        value={values.year}
                      >
                        <MenuItem value={2021}>2021</MenuItem>
                        <MenuItem value={2022}>2022</MenuItem>
                        <MenuItem value={2023}>2023</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box style={{ marginTop: "40px" }}>
                <Grid container spacing={3}>
                  <Grid item>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        label="Price"
                        margin="normal"
                        name="price"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.price}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        label="Strike Price"
                        margin="normal"
                        name="strikePrice"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.strikePrice}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        label="Existing User Price"
                        margin="normal"
                        name="existingUserPrice"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.existingUserPrice}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box style={{ marginTop: "20px", cursor: "pointer", zIndex: 10 }}>
                {Boolean(selectedSubscriptions) && (
                  <MultiSelect
                    options={subscriptions}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Discount Subscriptions"
                    components={animatedComponents}
                    onChange={setSelectedOption}
                    defaultValue={selectedSubscriptions}
                  />
                )}
                {!Boolean(selectedSubscriptions) && (
                  <MultiSelect
                    options={subscriptions}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Discount Subscriptions"
                    components={animatedComponents}
                    onChange={setSelectedOption}
                  />
                )}
              </Box>
              <Box style={{ marginTop: "30px" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        disablePast={false}
                        variant="inline"
                        inputVariant="outlined"
                        label="Start Date"
                        format="dd/MM/yyyy"
                        value={selectedDates.startDate}
                        onChange={(date) =>
                          setSelectedDates({
                            ...selectedDates,
                            startDate: date?.toJSON() ?? null,
                          })
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <p
                        style={{
                          fontSize: "0.75rem",
                          opacity: "50%",
                          marginTop: "5px",
                        }}
                      >
                        *Default start date is today
                      </p>
                    </Grid>
                    <Grid item>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        disablePast={true}
                        variant="inline"
                        inputVariant="outlined"
                        label="End Date"
                        format="dd/MM/yyyy"
                        value={selectedDates.endDate}
                        onChange={(date) =>
                          setSelectedDates({
                            ...selectedDates,
                            endDate: date?.toJSON() ?? null,
                          })
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <p
                        style={{
                          fontSize: "0.75rem",
                          opacity: "50%",
                          marginTop: "5px",
                        }}
                      >
                        *Default end date is 100 years from today
                      </p>
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Box>
              <Box>
                <FormControl component="fieldset" style={{ marginTop: "20px" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isVisible}
                          onChange={handleChange}
                          name="isVisible"
                        />
                      }
                      label="Is Visible"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isActive}
                          onChange={handleChange}
                          name="isActive"
                        />
                      }
                      label="Is Active"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.subscribeButton}
                          onChange={handleChange}
                          name="subscribeButton"
                        />
                      }
                      label="Subscribe Button"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isDelivery}
                          onChange={handleChange}
                          name="isDelivery"
                        />
                      }
                      label="Is Delivery"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.showEnquiry}
                          onChange={handleChange}
                          name="showEnquiry"
                        />
                      }
                      label="Show Enquiry"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isPopular}
                          onChange={handleChange}
                          name="isPopular"
                        />
                      }
                      label="Is Popular"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box style={{ marginTop: "20px" }} className="uploadBox-form">
                <p style={{ marginRight: "20px" }}>Image :*</p>
                {Boolean(id) && Boolean(getData?.result?.imgUrl) && (
                  <Tooltip title="Open Uploaded File" arrow>
                    <IconButton aria-label="open">
                      <OpenInBrowserIcon
                        color="secondary"
                        onClick={() =>
                          window.open(
                            `${AWS_URL}${getData?.result?.imgUrl}`,
                            "_blank"
                          )
                        }
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <FileUploader
                  setFileUrl={(url) =>
                    setFileUrls({ ...fileUrls, imgUrl: url })
                  }
                />
              </Box>
              <Box style={{ marginTop: "50px" }}>
                <p style={{ marginBottom: 10 }}>Invoice Details:</p>
                {invoiceData.map((item, index) => (
                  <Grid container alignItems="center" spacing={3}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      style={{ display: "inline-block", width: 300 }}
                    >
                      <TextField
                        label="Description"
                        margin="normal"
                        name="description"
                        style={{ width: "100%" }}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          let data = [...invoiceData] as [invoiceData];
                          data[index].description = e.target.value;
                          setInvoiceData(data);
                        }}
                        value={item.description}
                        variant="outlined"
                      />
                    </FormControl>
                    <Grid item>
                      <FormControl variant="outlined" className="dropdown-form">
                        <InputLabel>HSN</InputLabel>
                        <Select
                          onChange={(e) => {
                            let data = [...invoiceData] as [invoiceData];
                            data[index].hsn = e.target.value as number;
                            setInvoiceData(data);
                          }}
                          label="HSN"
                          name="hsn"
                          value={item.hsn}
                        >
                          {HSN.map((item) => (
                            <MenuItem value={item.value}>{item.key}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          size="medium"
                          style={{ width: 150 }}
                          label="Value Percentage"
                          margin="normal"
                          name="valuePercentage"
                          onChange={(e) => {
                            let data = [...invoiceData] as [invoiceData];
                            data[index].valuePercentage = e.target.value;
                            setInvoiceData(data);
                          }}
                          value={item.valuePercentage}
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          style={{ width: 150 }}
                          label="CGST"
                          margin="normal"
                          name="cgst"
                          onChange={(e) => {
                            let data = [...invoiceData] as [invoiceData];
                            data[index].cgstRate = e.target.value;
                            setInvoiceData(data);
                          }}
                          value={item.cgstRate}
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          style={{ width: 150 }}
                          label="SGST"
                          margin="normal"
                          name="sgst"
                          onChange={(e) => {
                            let data = [...invoiceData] as [invoiceData];
                            data[index].sgstRate = e.target.value;
                            setInvoiceData(data);
                          }}
                          value={item.sgstRate}
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          style={{ width: 150 }}
                          label="IGST"
                          margin="normal"
                          name="igst"
                          onChange={(e) => {
                            let data = [...invoiceData] as [invoiceData];
                            data[index].igstRate = e.target.value;
                            setInvoiceData(data);
                          }}
                          value={item.igstRate}
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                ))}
                <Grid container alignItems="center" spacing={3}>
                  <Button
                    href=""
                    color="primary"
                    size="small"
                    type="button"
                    variant="contained"
                    style={{
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "10px",
                    }}
                    onClick={() => {
                      setInvoiceData([
                        ...invoiceData,
                        ...[
                          {
                            hsn: null,
                            valuePercentage: null,
                            cgstRate: null,
                            sgstRate: null,
                            igstRate: null,
                            description: "",
                          },
                        ],
                      ] as [invoiceData]);
                    }}
                  >
                    {"Add"}
                  </Button>

                  {invoiceData.length > 1 && (
                    <Button
                      href=""
                      color="primary"
                      size="small"
                      type="button"
                      variant="contained"
                      style={{ marginRight: "20px" }}
                      onClick={() => {
                        // console.log(invoiceData, invoiceData.slice(0, -1));
                        setInvoiceData(
                          invoiceData.slice(0, -1) as [invoiceData]
                        );
                      }}
                    >
                      {"Remove"}
                    </Button>
                  )}
                </Grid>
              </Box>
              <Box style={{ marginTop: "50px" }}>
                <h4 style={{ marginBottom: "10px" }}>Subscription Details:</h4>
                <QuillTextEditor
                  setHtmlData={setTextEditorData}
                  htmlData={getData?.result?.details}
                />
              </Box>
              <Box style={{ marginTop: "50px" }}>
                <h4 style={{ marginBottom: "10px" }}>
                  Subscribed Details:{" "}
                  <span style={{ fontSize: "14px" }}>
                    (after user has subscribed)
                  </span>
                </h4>
                <QuillTextEditor
                  setHtmlData={setSubscribedTextEditorData}
                  htmlData={getData?.result?.subscribedDetails}
                />
              </Box>
              <Box style={{ marginTop: "50px" }}>
                <h4 style={{ marginBottom: "10px" }}>
                  Existing User Discount Label:{" "}
                </h4>
                <QuillTextEditor
                  setHtmlData={setDiscountInfoTextEditorData}
                  htmlData={getData?.result?.discountInfo}
                />
              </Box>
            </div>
            <Box style={{ marginTop: "100px" }}>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{ marginRight: "20px" }}
              >
                {id ? "Update Subscription" : "Create Subscription"}
              </Button>
              <Link to="/subscriptions">
                <Button
                  size="large"
                  variant="contained"
                  className={clsx(
                    "passiveButton",
                    "cancelButton",
                    "passiveButtonHover"
                  )}
                >
                  Cancel
                </Button>
              </Link>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error. Something went wrong.!
        </Alert>
      </Snackbar>
      <Toaster />
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default AddEditSubscription;
