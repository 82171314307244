import React, { useEffect, useState } from "react";
// import MultiSelect from "react-select";
import makeAnimated from "react-select/animated";
import DateFnsUtils from "@date-io/date-fns";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  TextField,
  Typography,
  //   Select,
  //   MenuItem,
  FormControl,
  //   InputLabel,
  Switch,
  FormGroup,
  FormControlLabel,
  Grid,
  Tooltip,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
// import Grid from "@material-ui/core/Grid";
import { fetcher } from "../../utils/Functions";
import { AWS_URL, staticOptions } from "../../utils/Constants";
import useSWR from "swr";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import QuillTextEditor from "../../components/Commons/QuillTextEditor";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import FileUploader from "../../components/Commons/FileUploader";

interface AddEditOfferAdProps {}

const animatedComponents = makeAnimated();

const AddEditOfferAd: React.FC<AddEditOfferAdProps> = ({}) => {
  const [openError, setOpenError] = useState(false);
  const [offerAdData, setOfferAdData] = useState<any>({});
  const [selectedTime, setSelectedTime] = useState<any>();
  const [fileUrls, setFileUrls] = useState({});

  let { id } = useParams();
  const navigate = useNavigate();

  // For POST Request
  const { data: postData, error: postError } = useSWR(
    !Boolean(id) ? [`/offerAd`, `post`, offerAdData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(postError) || !Boolean(postError?.status)) return;
    console.log(postError.info);
    setOpenError(true);
  }, [postError]);

  useEffect(() => {
    if (!Boolean(postData) || Boolean(postData?.error)) return;
    navigate("..", { replace: true });
  }, [postData]);

  //for GET Request to get offerAd details. Depends on id parameter
  const { data: getData, error: getError } = useSWR(
    Boolean(id) ? `/offerAd/${id}` : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(getData) || Boolean(getData?.error)) return;
    setSelectedTime(getData?.result.endTime);
  }, [getData]);

  useEffect(() => {
    if (!Boolean(getError) || !Boolean(getError?.status)) return;
    console.log(getError.info);
    setOpenError(true);
  }, [getError]);

  //For PUT Request. Depends on the id param
  const { data: putData, error: putError } = useSWR(
    Boolean(id) ? [`/offerAd/${id}`, `put`, offerAdData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(putError) || !Boolean(putError?.status)) return;
    console.log(putError.info);
    setOpenError(true);
  }, [putError]);

  useEffect(() => {
    if (!Boolean(putData) || Boolean(putData?.error)) return;
    navigate("../..", { replace: true });
  }, [putData]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: getData?.result?.name ?? "",
          url: getData?.result?.url ?? "",
          content: getData?.result?.content ?? "",
          isActive: getData?.result?.isActive ?? false,
          type: getData?.result?.type ?? "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required("OfferAd Name is required"),
        })}
        onSubmit={(values, actions) => {
          setOfferAdData({
            ...values,
            ...{ endTime: selectedTime },
            ...fileUrls,
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              backgroundColor: "white",
              padding: "30px",
              borderRadius: "15px",
            }}
          >
            <Box>
              <Typography
                color="textPrimary"
                variant="h4"
                style={{ marginBottom: "20px" }}
              >
                {id ? "Edit OfferAd:" : "Create a new OfferAd:"}
              </Typography>
            </Box>
            <div className="form-container">
              <TextField
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="OfferAd Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
                required
              />
              <TextField
                label="Content"
                margin="normal"
                name="content"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.content}
                variant="outlined"
              />
              <TextField
                label="OfferAd Url"
                margin="normal"
                name="url"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.url}
                variant="outlined"
              />
              <Box>
                <FormControl component="fieldset" style={{ marginTop: "20px" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isActive}
                          onChange={handleChange}
                          name="isActive"
                        />
                      }
                      label="Is Active"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box style={{ marginTop: "30px", marginBottom: "30px" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <DateTimePicker
                        disableToolbar
                        autoOk
                        disablePast={true}
                        variant="inline"
                        inputVariant="outlined"
                        label="End Date"
                        ampm={false}
                        value={selectedTime}
                        onChange={(date) => {
                          setSelectedTime(date);
                        }}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Box>
              <Grid item>
                <FormControl variant="outlined" className="dropdown-form">
                  <InputLabel>Type</InputLabel>
                  <Select
                    onChange={handleChange}
                    label="Type"
                    name="type"
                    value={values.type}
                  >
                    <MenuItem value="BANNER">BANNER</MenuItem>
                    <MenuItem value="CAROUSEL">CAROUSEL</MenuItem>
                    <MenuItem value="LINK">LINK</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Box style={{ marginTop: "20px" }} className="uploadBox-form">
                <p style={{ marginRight: "20px" }}>Desktop Image (600x384):</p>
                {Boolean(id) && Boolean(getData?.result?.desktopImg) && (
                  <Tooltip title="Open Uploaded File" arrow>
                    <IconButton aria-label="open">
                      <OpenInBrowserIcon
                        color="secondary"
                        onClick={() =>
                          window.open(
                            `${AWS_URL}${getData?.result?.desktopImg}`,
                            "_blank"
                          )
                        }
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <FileUploader
                  setFileUrl={(url) =>
                    setFileUrls({ ...fileUrls, desktopImg: url })
                  }
                />
              </Box>
              <Box style={{ marginTop: "20px" }} className="uploadBox-form">
                <p style={{ marginRight: "20px" }}>Mobile Image (375x384):*</p>
                {Boolean(id) && Boolean(getData?.result?.mobileImg) && (
                  <Tooltip title="Open Uploaded File" arrow>
                    <IconButton aria-label="open">
                      <OpenInBrowserIcon
                        color="secondary"
                        onClick={() =>
                          window.open(
                            `${AWS_URL}${getData?.result?.mobileImg}`,
                            "_blank"
                          )
                        }
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <FileUploader
                  setFileUrl={(url) =>
                    setFileUrls({ ...fileUrls, mobileImg: url })
                  }
                />
              </Box>
            </div>
            <Box style={{ marginTop: "50px" }}>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{ marginRight: "20px" }}
              >
                {id ? "Update OfferAd" : "Create OfferAd"}
              </Button>
              <Link to="/offerAds">
                <Button
                  size="large"
                  variant="contained"
                  className={clsx(
                    "passiveButton",
                    "cancelButton",
                    "passiveButtonHover"
                  )}
                >
                  Cancel
                </Button>
              </Link>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error. Something went wrong.!
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default AddEditOfferAd;
