import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useSWR from "swr";
import { fetcher } from "../../utils/Functions";
import { staticOptions, AWS_URL } from "../../utils/Constants";
import { FilePlus } from "react-feather";
import Table from "../../components/Commons/Table";
import LinearLoading from "../../components/Commons/LinearLoading";
import TimeField from "../../components/Commons/TimeField";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import LaunchIcon from "@material-ui/icons/Launch";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

interface QuestionProps {}

const Questions: React.FC<QuestionProps> = ({}) => {
  const [openError, setOpenError] = useState(false);
  const { data, error } = useSWR(`mainsTS/questions`, fetcher, staticOptions);

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    setOpenError(true);
  }, [error]);

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;
    data?.result?.mainsTSQuestionsList?.forEach((item) => {
      item["mainsTSDetails"] = "";
      item.questionPdfUrl =
        Boolean(item.questionPdfUrl) && getLinkIcon(item.questionPdfUrl);
      item["answer"] = "";
      item["answer1"] =
        Boolean(item?.bestAnswers?.[0]) &&
        getLinkIcon(item?.bestAnswers?.[0]?.copy ?? "");
      item["answer2"] =
        Boolean(item?.bestAnswers?.[1]) &&
        getLinkIcon(item?.bestAnswers?.[1]?.copy ?? "");
      item["answer3"] =
        Boolean(item?.bestAnswers?.[2]) &&
        getLinkIcon(item?.bestAnswers?.[2]?.copy ?? "");
      item["showBestAnswers"] = (
        item["showBestAnswers"] as boolean
      )?.toString();
      item.createdAt = timeField(item.createdAt);
    });
  }, [data]);

  function timeField(date: string) {
    return <TimeField date={date} showTime={true} />;
  }

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  function getLinkIcon(url: string) {
    return (
      <Tooltip title="Open File" arrow>
        <IconButton aria-label="open">
          <LaunchIcon
            color="action"
            onClick={() => window.open(`${AWS_URL}${url}`, "_blank")}
          />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <div>
      <Link to="addQuestions">
        <div
          className={clsx(
            "addMemberButton",
            "buttonEffectShadow",
            "activeButton"
          )}
        >
          <FilePlus size={"20px"} />
          Add Question
        </div>
      </Link>
      {!!!data?.result?.mainsTSQuestionsList ? (
        <LinearLoading />
      ) : (
        <Table
          data={data?.result?.mainsTSQuestionsList}
          uniqueId={"_id"}
          enableEdit={true}
          enableDelete={true}
          navTo={"questions/editQuestions"}
          apiEndpoint={"mainsTS/questions"}
          dataOrder={[
            "name",
            "mainsTSName",
            "questionPdfUrl",
            "vdoId",
            "noOfQuestions",
            "noOfEssays",
            "maxScore",
            "answer1",
            "answer2",
            "answer3",
            "showBestAnswers",
          ]}
          filter
        />
      )}
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while fetching Notes.
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default Questions;
