import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { Link } from "react-router-dom";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import LinearLoading from "../../components/Commons/LinearLoading";
import Table from "../../components/Commons/Table";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import TimeField from "../../components/Commons/TimeField";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import clsx from "clsx";

interface CustomersProps {}

const Customers: React.FC<CustomersProps> = ({}) => {
  const [openError, setOpenError] = useState(false);
  const { data, error } = useSWR(
    `/users/onBoarding?role=EVALUATOR`,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    console.log(error.info);
    setOpenError(true);
  }, [error]);

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;
    data?.result.forEach((item) => {
      item.isPhoneVerified = item.isPhoneVerified.toString();
      item.createdAt = timeField(item.createdAt);
    });
  }, [data]);

  function timeField(date: string) {
    return <TimeField date={date} showTime={true} />;
  }

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  return (
    <div>
      <Link to="/addEvaluator">
        <div
          className={clsx(
            "addMemberButton",
            "buttonEffectShadow",
            "activeButton"
          )}
          style={{ width: "140px" }}
        >
          <PersonAddIcon />
          On Board
        </div>
      </Link>
      {!!!data ? (
        <LinearLoading />
      ) : (
        <Table
          data={data?.result}
          uniqueId={"uid"}
          enableDelete={true}
          enableEdit={true}
          apiEndpoint={"users/onBoarding"}
          mutateKey={"users?role=EVALUATOR"}
          navTo={"onBoarding"}
          dataOrder={[
            "name",
            "uid",
            "email",
            "phone",
            "isPhoneVerified",
            "createdAt",
          ]}
          search={true}
        />
      )}
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while fetching Customers List.
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default Customers;
