import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useSWR from "swr";
import { fetcher, getUserRoles } from "../../utils/Functions";
import { staticOptions, TASK_STATUS_OBJECT } from "../../utils/Constants";
import Table from "../../components/Commons/Table";
import LinearLoading from "../../components/Commons/LinearLoading";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import TimeField from "../../components/Commons/TimeField";
import Filter from "../../components/Commons/Filter";

interface TasksProps {}

const DelayedText = ({ children, isDelayed }) => {
  return <p style={{ color: isDelayed ? "red" : "" }}>{children}</p>;
};

const Tasks: React.FC<TasksProps> = ({}) => {
  //     const [limit, setLimit] = useState<number>(50);
  //   const [page, setPage] = useState<number>(1);
  const [queryString, setQueryString] = useState<string>(``);
  const { data, error } = useSWR(
    `/tasks?${queryString}`,
    fetcher,
    staticOptions
  );

  const { data: memberData, error: memberError } = useSWR(
    getUserRoles.isAdmin ? `/users?role=MEMBER` : null,
    fetcher,
    staticOptions
  );
  const [openError, setOpenError] = useState(false);

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    setOpenError(true);
  }, [error]);

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;
    data?.result.forEach((item, index) => {
      let isDelayed =
        item.dueDate && item.status == "PENDING"
          ? new Date(item.dueDate) < new Date()
          : false;
      item.name = (
        <DelayedText isDelayed={isDelayed}>{item.userId?.name}</DelayedText>
      );
      item.title = (
        <DelayedText isDelayed={isDelayed}>{item?.title}</DelayedText>
      );
      item.status = (
        <DelayedText isDelayed={isDelayed}>{item?.status}</DelayedText>
      );
      item.type = <DelayedText isDelayed={isDelayed}>{item?.type}</DelayedText>;
      item.completedAt = <TimeField date={item.completedAt} showTime={true} />;
      item.isAcknowledged = item.isAcknowledged?.toString();
      item.dueDate = <TimeField date={item.dueDate} showTime={true} />;
      item.isDelayMarked = item.isDelayMarked?.toString();
    });
  }, [data]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const filters = [
    {
      name: "Member",
      filterType: "dropDown",
      options: memberData?.result?.map((item) => {
        return { label: item.name, id: item._id };
      }),
      defaultValue: { label: "", id: "" },
      param: "userId",
    },
    {
      name: "Status",
      filterType: "dropDown",
      options: Object.keys(TASK_STATUS_OBJECT).map((key) => {
        return { label: key, id: TASK_STATUS_OBJECT[key] };
      }),

      defaultValue: { label: "", id: "" },
      param: "status",
    },
    {
      name: "Type",
      filterType: "dropDown",
      options: [
        { label: "Daily", id: "DAILY" },
        { label: "Deadline", id: "DEADLINE" },
      ],

      defaultValue: { label: "", id: "" },
      param: "type",
    },
    {
      name: "Acknowledge",
      filterType: "dropDown",
      options: [
        { label: "Acknowledged", id: "true" },
        { label: "Unacknowledged", id: "false" },
      ],

      defaultValue: { label: "", id: "" },
      param: "isAcknowledged",
    },
    // {
    //   name: "Due Date",
    //   filterType: "date",
    //   // options:[],
    //   // defaultValue:"",
    //   param: "dueDate",
    // },
  ];

  return (
    <div>
      <Link to="/tasks/addTask">
        <div
          className={clsx(
            "addMemberButton",
            "buttonEffectShadow",
            "activeButton"
          )}
          style={{ width: "140px" }}
        >
          <LibraryAddIcon />
          Add Task
        </div>
      </Link>
      {getUserRoles.isAdmin && (
        <Filter
          filters={filters}
          setQueryString={(query) => {
            setQueryString(query);
            //   setPage(1);
          }}
        />
      )}
      {!!!data ? (
        <LinearLoading />
      ) : (
        <Table
          data={data?.result}
          uniqueId={"_id"}
          enableEdit={true}
          enableDelete={getUserRoles.isAdmin}
          navTo={"tasks/editTask"}
          apiEndpoint={"tasks"}
          dataOrder={
            getUserRoles.isAdmin
              ? [
                  "title",
                  "name",
                  "adminComment",
                  "type",
                  "status",
                  "dueDate",
                  "completedAt",
                  "memberComment",
                  "isAcknowledged",
                  "isDelayMarked",
                ]
              : ["title", "type", "status", "dueDate", "completedAt"]
          }
        />
      )}
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while fetching Notes Libraries.
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default Tasks;
