import React, { useEffect, useState } from "react";
import MultiSelect from "react-select";
import makeAnimated from "react-select/animated";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormGroup,
  FormControlLabel,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { fetcher, getUserRoles } from "../../utils/Functions";
import {
  AWS_URL,
  OPTIONALS,
  PCS,
  staticOptions,
  TASK_STATUS_OBJECT,
} from "../../utils/Constants";
import useSWR from "swr";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import {
  DateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import FileUploader from "../../components/Commons/FileUploader";

interface AddEditTaskProps {}

const animatedComponents = makeAnimated();

const AddEditTask: React.FC<AddEditTaskProps> = ({}) => {
  const [openError, setOpenError] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState<any>(null);
  const [taskData, setTaskData] = useState({});
  const [selectedDates, setSelectedDates] = useState<{
    dueDate: Date | string | null;
  }>({ dueDate: null });
  const [fileUrls, setFileUrls] = useState({});

  let { id } = useParams();
  const navigate = useNavigate();

  const { data, error } = useSWR(
    getUserRoles.isAdmin ? `/users?role=MEMBER` : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(error) || !Boolean(data?.status)) return;
    setOpenError(true);
  }, [error]);

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;
    const dropdownData: any = [];
    data?.result?.forEach((item) => {
      let option = { value: item._id, name: item.name };
      dropdownData.push(option);
    });
    setMembers(dropdownData);
  }, [data]);

  // For POST Request
  const { data: postData, error: postError } = useSWR(
    !Boolean(id) ? [`/tasks`, `post`, taskData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(postError) || !Boolean(postError?.status)) return;
    setOpenError(true);
  }, [postError]);

  useEffect(() => {
    if (!Boolean(postData) || Boolean(postData?.error)) return;
    navigate("..", { replace: true });
  }, [postData]);

  //for GET Request to get task details. Depends on id parameter
  const { data: getData, error: getError } = useSWR(
    Boolean(id) ? `/tasks/${id}` : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(getError) || !Boolean(getError?.status)) return;
    setOpenError(true);
  }, [getError]);

  useEffect(() => {
    if (!Boolean(getData) || Boolean(getData?.error)) return;
    let user = getData?.result?.userId;
    let modifiedMember: any;
    modifiedMember = { value: user._id, name: user.name };
    setSelectedOption(modifiedMember);
    setSelectedMember(modifiedMember);
    setSelectedDates({ dueDate: getData?.result?.dueDate });
  }, [getData]);

  //For PUT Request. Depends on the id param
  const { data: putData, error: putError } = useSWR(
    Boolean(id) ? [`/tasks/${id}`, `put`, taskData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(putError) || !Boolean(putError?.status)) return;
    setOpenError(true);
  }, [putError]);

  useEffect(() => {
    if (!Boolean(putData) || Boolean(putData?.error)) return;
    navigate("../..", { replace: true });
  }, [putData]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          title: getData?.result?.title ?? "",
          description: getData?.result?.description ?? "",
          status: getData?.result?.status ?? "",
          adminComment: getData?.result?.adminComment ?? "",
          memberComment: getData?.result?.memberComment ?? "",
          type: getData?.result?.type ?? "",
          link: getData?.result?.link ?? "",
          isAcknowledged: getData?.result?.isAcknowledged ?? false,
          isDelayMarked: getData?.result?.isDelayMarked ?? false,
        }}
        onSubmit={(values, actions) => {
          setTaskData({
            ...values,
            userId: selectedOption?.value ?? null,
            ...selectedDates,
            ...fileUrls,
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              backgroundColor: "white",
              padding: "30px",
              borderRadius: "15px",
            }}
          >
            <Box>
              <Typography
                color="textPrimary"
                variant="h4"
                style={{ marginBottom: "20px" }}
              >
                {id ? "Edit Task:" : "Create a new Task:"}
              </Typography>
            </Box>
            <div className="form-container">
              <TextField
                disabled={!getUserRoles.isAdmin && Boolean(id)}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                label="Task Title"
                margin="normal"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                variant="outlined"
                required
              />
              <TextField
                disabled={!getUserRoles.isAdmin && Boolean(id)}
                label="Description"
                multiline
                rows={2}
                rowsMax={4}
                margin="normal"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                value={values.description}
              />
              <TextField
                // disabled={!getUserRoles.isAdmin && Boolean(id)}
                label="Link"
                multiline
                rows={2}
                rowsMax={4}
                margin="normal"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                value={values.link}
              />
              <TextField
                disabled={!getUserRoles.isAdmin}
                label="Admin Comment"
                multiline
                rows={2}
                rowsMax={4}
                margin="normal"
                name="adminComment"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                value={values.adminComment}
              />
              <TextField
                // disabled={!getUserRoles.isAdmin}
                label="Member Comment"
                multiline
                rows={2}
                rowsMax={4}
                margin="normal"
                name="memberComment"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                value={values.memberComment}
              />
              {getUserRoles.isAdmin && (
                <Box
                  style={{ marginTop: "20px", cursor: "pointer", zIndex: 10 }}
                >
                  {Boolean(selectedMember) && (
                    <Autocomplete
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            value={selectedMember?.name ?? ""}
                            label={"member"}
                            variant="outlined"
                          />
                        );
                      }}
                      options={members}
                      getOptionLabel={(option: any) => {
                        return option.name || "";
                      }}
                      placeholder="Select Member"
                      onChange={(e, value) => {
                        setSelectedOption(value);
                      }}
                      value={selectedMember}
                    />
                  )}
                  {!Boolean(selectedMember) && (
                    <Autocomplete
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            value={selectedMember?.label ?? ""}
                            label={"Select Member"}
                            variant="outlined"
                          />
                        );
                      }}
                      options={members}
                      getOptionLabel={(option: any) => {
                        return option.name || "";
                      }}
                      placeholder="Select Member"
                      onChange={(e, value) => {
                        setSelectedOption(value);
                      }}
                    />
                  )}
                </Box>
              )}
              <div style={{ marginTop: "20px" }}>
                <FormControl
                  variant="outlined"
                  className="dropdown-form"
                  style={{ marginRight: "20px" }}
                >
                  <InputLabel>Type</InputLabel>
                  <Select
                    onChange={handleChange}
                    label="Type"
                    name="type"
                    value={values.type}
                    disabled={!getUserRoles.isAdmin && Boolean(id)}
                  >
                    <MenuItem value="DAILY">Daily</MenuItem>
                    <MenuItem value="DEADLINE">Deadline</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {values.type != "DAILY" && (
                <Box style={{ marginTop: "30px" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item>
                      <DateTimePicker
                        disabled={!getUserRoles.isAdmin && Boolean(id)}
                        disableToolbar
                        autoOk
                        disablePast={false}
                        variant="inline"
                        inputVariant="outlined"
                        label="Due Date"
                        ampm={false}
                        value={selectedDates.dueDate}
                        onChange={(date) => {
                          setSelectedDates({
                            dueDate: date ?? null,
                          });
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Box>
              )}
              <Box style={{ marginTop: "20px" }} className="uploadBox-form">
                <p style={{ marginRight: "20px" }}>Admin Attachment:</p>
                {Boolean(id) && Boolean(getData?.result?.attachmentUrl) && (
                  <Tooltip title="Open Uploaded File" arrow>
                    <IconButton aria-label="open">
                      <OpenInBrowser
                        color="secondary"
                        onClick={() =>
                          window.open(
                            `${AWS_URL}${getData?.result?.attachmentUrl}`,
                            "_blank"
                          )
                        }
                      />
                    </IconButton>
                  </Tooltip>
                )}

                {getUserRoles.isAdmin && (
                  <FileUploader
                    setFileUrl={(url) => setFileUrls({ attachmentUrl: url })}
                    includeAllTypes={true}
                  />
                )}
              </Box>
              <Box style={{ marginTop: "20px" }} className="uploadBox-form">
                <p style={{ marginRight: "20px" }}>Member Attachment:</p>
                {Boolean(id) &&
                  Boolean(getData?.result?.memberAttachmentUrl) && (
                    <Tooltip title="Open Uploaded File" arrow>
                      <IconButton aria-label="open">
                        <OpenInBrowser
                          color="secondary"
                          onClick={() =>
                            window.open(
                              `${AWS_URL}${getData?.result?.memberAttachmentUrl}`,
                              "_blank"
                            )
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                {
                  <FileUploader
                    setFileUrl={(url) =>
                      setFileUrls({ memberAttachmentUrl: url })
                    }
                    includeAllTypes={true}
                  />
                }
              </Box>
              {values.type != "DAILY" && (
                <Box style={{ marginTop: "30px" }}>
                  <Grid item>
                    <FormControl variant="outlined" className="dropdown-form">
                      <InputLabel>Status</InputLabel>
                      <Select
                        onChange={handleChange}
                        label="Status"
                        name="status"
                        value={values.status}
                        disabled={
                          new Date(selectedDates?.dueDate!!) < new Date() ??
                          true
                        }
                      >
                        {Object.keys(TASK_STATUS_OBJECT).map((key) => {
                          return (
                            <MenuItem value={TASK_STATUS_OBJECT[key]}>
                              {key}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {new Date(selectedDates?.dueDate!!) < new Date() &&
                      values.status == "PENDING" && (
                        <p style={{ marginTop: 10, color: "red" }}>
                          Since the deadline has passed create a new task with
                          updated deadline.
                        </p>
                      )}
                  </Grid>
                </Box>
              )}
              {getUserRoles.isAdmin && (
                <Box>
                  <FormControl
                    component="fieldset"
                    style={{ marginTop: "20px" }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.isAcknowledged}
                            onChange={handleChange}
                            name="isAcknowledged"
                          />
                        }
                        label="Is Acknowledged"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.isDelayMarked}
                            onChange={handleChange}
                            name="isDelayMarked"
                          />
                        }
                        label="Is Delayed"
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              )}
            </div>
            <Box style={{ marginTop: "50px" }}>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{ marginRight: "20px" }}
              >
                {id ? "Update Task" : "Create Task"}
              </Button>
              <Link to="/tasks">
                <Button
                  size="large"
                  variant="contained"
                  className={clsx(
                    "passiveButton",
                    "cancelButton",
                    "passiveButtonHover"
                  )}
                >
                  Cancel
                </Button>
              </Link>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error. Something went wrong.!
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default AddEditTask;
