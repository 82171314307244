import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useSWR from "swr";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import Table from "../../components/Commons/Table";
import LinearLoading from "../../components/Commons/LinearLoading";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";

interface ModulesProps {}

const Modules: React.FC<ModulesProps> = ({}) => {
  const { data, error } = useSWR(`/modules`, fetcher, staticOptions);
  const [openError, setOpenError] = useState(false);

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    console.log(error.info);
    setOpenError(true);
  }, [error]);

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;
    data?.result.forEach((item) => {
      item.isVisible = item.isVisible.toString();
      item.isActive = item.isActive.toString();
      item.subscriptions?.length > 0 &&
        Array.isArray(item.subscriptions) &&
        (item.subscriptions = item?.subscriptions?.join(", "));
    });
  }, [data]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  return (
    <div>
      <Link to="/modules/addModule">
        <div
          className={clsx(
            "addMemberButton",
            "buttonEffectShadow",
            "activeButton"
          )}
          style={{ width: "140px" }}
        >
          <LibraryAddIcon />
          Add Module
        </div>
      </Link>
      {!!!data ? (
        <LinearLoading />
      ) : (
        <Table
          data={data?.result}
          uniqueId={"id"}
          enableEdit={true}
          enableDelete={true}
          navTo={"modules/editModule"}
          apiEndpoint={"modules"}
          dataOrder={[
            "name",
            "id",
            "section",
            "type",
            "pcs",
            "optional",
            "year",
            "isVisible",
            "isActive",
          ]}
        />
      )}
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while fetching Notes Libraries.
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default Modules;
