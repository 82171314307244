import React, { useRef, useState, useMemo } from "react";
import {
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Button,
} from "@material-ui/core";

export default function CustomInput({
  label,
  data,
  valueKey,
  handleSubmit,
  validationSchema,
}) {
  const number = useRef(null);
  const { answerId, initialNumber } = data;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isError = useMemo(() => {
    if (error) {
      return true;
    }
    return false;
  }, [error]);
  return (
    <>
      <InputLabel
        // style={{top:0,alignSelf:'flex-start'}}
        shrink
        required={true}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        required={true}
        style={{ width: 70 }}
        type="number"
        error={isError}
        defaultValue={initialNumber}
        onChange={(e) => {
          number.current = e.target.value;
          validationSchema
            .validate({ number: number.current })
            .then((value) => {
              console.log(value);
              setError(false);
            })
            .catch((err) => {
              setErrorMessage(err.message);
              setError(true);
            });
        }}
        // onKeyPress={(ev) => {
        //   if (ev.key === "Enter") {
        //     // Do code here
        //     ev.preventDefault();
        //     if(!error){
        //       console.log(number.current)
        //       handleSubmit({
        //         answerId: answerId,
        //         [valueKey]: number.current,
        //       })
        //     }
        //     return
        //   }
        // }}
      />
      <Button
        color="primary"
        size="small"
        type="submit"
        variant="contained"
        style={{ marginRight: "20px" }}
        onClick={() => {
          if (!error) {
            // console.log(number.current)
            handleSubmit({
              answerId: answerId,
              [valueKey]: number.current,
            });
          }
          return;
        }}
      >
        Submit
      </Button>
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </>
  );
}
