import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import "../../assets/style/Notes.css";
import { fetcher } from "../../utils/Functions";
import { staticOptions, AWS_URL } from "../../utils/Constants";
import useSWR from "swr";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import FileUploader from "../../components/Commons/FileUploader";
import { useParams } from "react-router-dom";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import QuillTextEditor from "../../components/Commons/QuillTextEditor";

interface Props {}

const AddQuestions = (props: Props) => {
  const [questionsData, setQuestionsData] = useState<any>({});
  const [openError, setOpenError] = useState(false);
  const [fileUrls, setFileUrls] = useState({});
  const [selectedDates, setSelectedDates] = useState<{
    startDate: Date | string | null;
    endDate: Date | string | null;
    visibleDate?: Date | string | null;
  }>({ startDate: null, endDate: null, visibleDate: null });
  const [syllabus, setSyllabus] = useState<string>("");

  let { id } = useParams();
  const navigate = useNavigate();

  //For GET Request. Depends on the id param
  const { data: getData, error: getError } = useSWR(
    Boolean(id) ? `/mainsTS/questions/${id}` : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(getData) || Boolean(getData?.error)) return;
    setSelectedDates({
      startDate: Boolean(getData?.result.startDate)
        ? new Date(getData?.result.startDate)
        : null,
      endDate: Boolean(getData?.result.endDate)
        ? new Date(getData?.result.endDate)
        : null,
      visibleDate: getData?.result.visibleDate ?? null,
    });
  }, [getData]);

  useEffect(() => {
    if (!Boolean(getError) || !Boolean(getError?.status)) return;
    console.log(getError.info);
    setOpenError(true);
  }, [getError]);

  // For POST Request
  const { data: postData, error: postError } = useSWR(
    !Boolean(id)
      ? [
          `/mainsTS/` + questionsData.mainsTSId + `/questions`,
          `post`,
          questionsData,
        ]
      : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(postError) || !Boolean(postError?.status)) return;
    console.log(postError.info);
    setOpenError(true);
  }, [postError]);

  useEffect(() => {
    if (!Boolean(postData) || Boolean(postData?.error)) return;
    navigate("..", { replace: true });
  }, [postData]);

  //For PUT Request. Depends on the id param
  const { data: putData, error: putError } = useSWR(
    Boolean(id) ? [`/mainsTS/questions/${id}`, `put`, questionsData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(putError) || !Boolean(putError?.status)) return;
    console.log(putError.info);
    setOpenError(true);
  }, [putError]);

  useEffect(() => {
    if (!Boolean(putData) || Boolean(putData?.error)) return;
    navigate("../..", { replace: true });
  }, [putData]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const { data: mainsTSData, error: getMainsTSError } = useSWR(
    `mainsTS`,
    fetcher,
    staticOptions
  );

  const setTextEditorData = (data: string) => {
    setSyllabus(data);
  };

  const saveBestAnswer = async (answerId, data) => {
    fetcher(`mainsTS/questions/bestAnswers/${id}`, "put", {
      answerId,
      bestAnswer: data.bestAnswer,
      copy: data.copy,
    })
      .then((res) => {
        window.location.reload();
      })
      .catch((e) => {
        setOpenError(true);
      });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: getData?.result?.name ?? "",
          description: getData?.result?.description ?? "",
          mainsTSId: getData?.result?.mainsTSId ?? "",
          questionPdfUrl: getData?.result?.questionPdfUrl ?? "",
          modelAnswerPdfUrl: getData?.result?.modelAnswerPdfUrl ?? "",
          answerBookletPdfUrl: getData?.result?.answerBookletPdfUrl ?? "",
          noOfEssays: getData?.result?.noOfEssays ?? "",
          noOfQuestions: getData?.result?.noOfQuestions ?? "",
          maxScore: getData?.result?.maxScore ?? "",
          isVisible: getData?.result?.isVisible ?? true,
          isActive: getData?.result?.isActive ?? true,
          vdoId: getData?.result?.vdoId ?? "",
          copy0:
            getData?.result?.bestAnswers?.[0]?.copy ??
            getData?.result?.bestAnswers?.[0]?.evaluatedPdfUrl ??
            "",
          evaluated0: getData?.result?.bestAnswers?.[0]?.evaluatedPdfUrl ?? "",
          answer0: getData?.result?.bestAnswers?.[0]?.answerPdfUrl ?? "",
          copy1:
            getData?.result?.bestAnswers?.[1]?.copy ??
            getData?.result?.bestAnswers?.[1]?.evaluatedPdfUrl ??
            "",
          evaluated1: getData?.result?.bestAnswers?.[1]?.evaluatedPdfUrl ?? "",
          answer1: getData?.result?.bestAnswers?.[1]?.answerPdfUrl ?? "",
          copy2:
            getData?.result?.bestAnswers?.[2]?.copy ??
            getData?.result?.bestAnswers?.[2]?.evaluatedPdfUrl ??
            "",
          evaluated2: getData?.result?.bestAnswers?.[2]?.evaluatedPdfUrl ?? "",
          answer2: getData?.result?.bestAnswers?.[2]?.answerPdfUrl ?? "",
          showBestAnswers: getData?.result?.showBestAnswers ?? false,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required("Questions Name is required"),
          description: Yup.string().required("Description is required"),
        })}
        onSubmit={(values, actions) => {
          let bestAnswers = getData?.result?.bestAnswers || [];
          if (bestAnswers.length) {
            for (let i = 0; i < 3; i++) {
              if (!values["copy" + i.toString()] || !bestAnswers[i]) continue;
              bestAnswers[i].copy = values["copy" + i.toString()];
            }
          }
          if (!selectedDates.visibleDate) {
            delete selectedDates.visibleDate;
          }
          setQuestionsData({
            ...values,
            ...fileUrls,
            ...selectedDates,
            ...(Boolean(syllabus) && { syllabus }),
            bestAnswers,
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              backgroundColor: "white",
              padding: "30px",
              borderRadius: "15px",
            }}
          >
            <Box>
              <Typography
                color="textPrimary"
                variant="h4"
                style={{ marginBottom: "20px" }}
              >
                {id ? "Edit questions:" : "Create a new questions:"}
              </Typography>
            </Box>
            <div className="form-container">
              <TextField
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Question Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
                required
              />
              <TextField
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                label="Description"
                multiline
                rows={2}
                rowsMax={4}
                margin="normal"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                value={values.description}
                required
              />
              <TextField
                error={Boolean(touched.noOfEssays && errors.noOfEssays)}
                helperText={touched.noOfEssays && errors.noOfEssays}
                label="No of Essays"
                margin="normal"
                type="number"
                name="noOfEssays"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.noOfEssays}
                variant="outlined"
                required
              />
              <TextField
                error={Boolean(touched.noOfQuestions && errors.noOfQuestions)}
                helperText={touched.noOfQuestions && errors.noOfQuestions}
                label="No of Questions"
                margin="normal"
                type="number"
                name="noOfQuestions"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.noOfQuestions}
                variant="outlined"
                required
              />
              <TextField
                error={Boolean(touched.maxScore && errors.maxScore)}
                helperText={touched.maxScore && errors.maxScore}
                label="Maximum Score"
                margin="normal"
                type="number"
                name="maxScore"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.maxScore}
                variant="outlined"
                required
              />
              <div style={{ marginTop: "20px" }}>
                <FormControl
                  variant="outlined"
                  className="dropdown-form"
                  style={{ marginRight: "20px" }}
                >
                  <InputLabel>TestSeries</InputLabel>
                  <Select
                    onChange={handleChange}
                    label="MainsTS"
                    name="mainsTSId"
                    value={values.mainsTSId}
                  >
                    {mainsTSData?.result?.mainsTSList.map((item) => {
                      return <MenuItem value={item._id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
              <Box>
                <FormControl component="fieldset" style={{ marginTop: "20px" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isVisible}
                          onChange={handleChange}
                          name="isVisible"
                        />
                      }
                      label="Is Visible"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isActive}
                          onChange={handleChange}
                          name="isActive"
                        />
                      }
                      label="Is Active (if true, students can upload answers. But students cannot upload answers past the end date.)"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box style={{ marginTop: "30px" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        disablePast={false}
                        variant="inline"
                        inputVariant="outlined"
                        label="Start Date"
                        format="dd/MM/yyyy"
                        value={selectedDates.startDate}
                        onChange={(date) => {
                          setSelectedDates({
                            ...selectedDates,
                            startDate:
                              new Date(date?.setHours(0, 0, 0, 0)!!).toJSON() ??
                              null,
                          });
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <p
                        style={{
                          fontSize: "0.75rem",
                          opacity: "50%",
                          marginTop: "5px",
                        }}
                      >
                        *Default start date is today
                      </p>
                    </Grid>
                    <Grid item>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        disablePast={false}
                        variant="inline"
                        inputVariant="outlined"
                        label="End Date"
                        format="dd/MM/yyyy"
                        value={selectedDates.endDate}
                        onChange={(date) =>
                          setSelectedDates({
                            ...selectedDates,
                            endDate:
                              new Date(
                                date?.setHours(10, 0, 0, 0)!!
                              ).toJSON() ?? null,
                          })
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <p
                        style={{
                          fontSize: "0.75rem",
                          opacity: "50%",
                          marginTop: "5px",
                        }}
                      >
                        *Default end date is 10 years from today
                      </p>
                    </Grid>
                    <Grid item>
                      <DateTimePicker
                        disableToolbar
                        // autoOk
                        // disablePast={true}
                        variant="inline"
                        inputVariant="outlined"
                        label="Visible Date"
                        ampm={false}
                        value={selectedDates.visibleDate ?? null}
                        onChange={(date) => {
                          setSelectedDates({
                            ...selectedDates,
                            visibleDate: date,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Box>
              <Box style={{ marginTop: "40px" }} className="uploadBox-form">
                <p style={{ marginRight: "20px" }}>Upload Question Paper:*</p>
                {Boolean(id) && Boolean(getData?.result?.questionPdfUrl) && (
                  <Tooltip title="Open Uploaded File" arrow>
                    <IconButton aria-label="open">
                      <OpenInBrowserIcon
                        color="secondary"
                        onClick={() =>
                          window.open(
                            `${AWS_URL}${getData?.result?.questionPdfUrl}`,
                            "_blank"
                          )
                        }
                      />
                    </IconButton>
                  </Tooltip>
                )}

                <FileUploader
                  setFileUrl={(url) =>
                    // setFileUrls({ ...fileUrls, questionPdfUrl: url })
                    (values.questionPdfUrl = url)
                  }
                />
              </Box>
              <Box style={{ marginTop: "40px" }} className="uploadBox-form">
                <p style={{ marginRight: "20px" }}>
                  Upload Model Answer Paper:*
                </p>
                {Boolean(id) && Boolean(getData?.result?.modelAnswerPdfUrl) && (
                  <Tooltip title="Open Uploaded File" arrow>
                    <IconButton aria-label="open">
                      <OpenInBrowserIcon
                        color="secondary"
                        onClick={() =>
                          window.open(
                            `${AWS_URL}${getData?.result?.modelAnswerPdfUrl}`,
                            "_blank"
                          )
                        }
                      />
                    </IconButton>
                  </Tooltip>
                )}

                <FileUploader
                  setFileUrl={(url) =>
                    // setFileUrls({ ...fileUrls, modelAnswerPdfUrl: url })
                    (values.modelAnswerPdfUrl = url)
                  }
                />
              </Box>
              <Box style={{ marginTop: "40px" }} className="uploadBox-form">
                <p style={{ marginRight: "20px" }}>Upload Answer Booklet:*</p>
                {Boolean(id) && Boolean(getData?.result?.answerBookletPdfUrl) && (
                  <Tooltip title="Open Uploaded File" arrow>
                    <IconButton aria-label="open">
                      <OpenInBrowserIcon
                        color="secondary"
                        onClick={() =>
                          window.open(
                            `${AWS_URL}${getData?.result?.answerBookletPdfUrl}`,
                            "_blank"
                          )
                        }
                      />
                    </IconButton>
                  </Tooltip>
                )}

                <FileUploader
                  setFileUrl={(url) =>
                    // setFileUrls({ ...fileUrls, answerBookletPdfUrl: url })
                    (values.answerBookletPdfUrl = url)
                  }
                />
              </Box>
              <Box style={{ marginTop: "40px" }} className="uploadBox-form">
                <p style={{ marginRight: "20px" }}>Best Answer 1:*</p>
                {Boolean(id) &&
                  Boolean(
                    getData?.result?.bestAnswers?.[0]?.evaluatedPdfUrl
                  ) && (
                    <Tooltip title="Open Saved Copy" arrow>
                      <IconButton aria-label="open">
                        <OpenInBrowserIcon
                          color="secondary"
                          onClick={() =>
                            window.open(
                              `${AWS_URL}${
                                getData?.result?.bestAnswers?.[0]?.copy ??
                                getData?.result?.bestAnswers?.[0]
                                  ?.evaluatedPdfUrl ??
                                ""
                              }`,
                              "_blank"
                            )
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                <FileUploader
                  setFileUrl={(url) =>
                    // setFileUrls({ ...fileUrls, questionPdfUrl: url })
                    (values.copy0 = url)
                  }
                />
                {Boolean(
                  getData?.result?.bestAnswers?.[0]?.evaluatedPdfUrl
                ) && (
                  <Tooltip title="Open Evaluated Pdf" arrow>
                    <IconButton aria-label="open">
                      <OpenInBrowserIcon
                        color="secondary"
                        onClick={() =>
                          window.open(
                            `${AWS_URL}${
                              getData?.result?.bestAnswers?.[0]
                                ?.evaluatedPdfUrl ?? ""
                            }`,
                            "_blank"
                          )
                        }
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {Boolean(getData?.result?.bestAnswers?.[0]?.answerPdfUrl) && (
                  <Tooltip title="Open Answer Pdf" arrow>
                    <IconButton aria-label="open">
                      <OpenInBrowserIcon
                        color="secondary"
                        onClick={() =>
                          window.open(
                            `${AWS_URL}${
                              getData?.result?.bestAnswers?.[0]?.answerPdfUrl ??
                              ""
                            }`,
                            "_blank"
                          )
                        }
                      />
                    </IconButton>
                  </Tooltip>
                )}

                <button
                  style={{
                    backgroundColor: "lightgray",
                    borderRadius: 5,
                    marginLeft: 10,
                    padding: 5,
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    // setFileUrl({ pdf: "" });
                    saveBestAnswer(getData?.result?.bestAnswers?.[0]._id, {
                      bestAnswer: true,
                      copy: values.copy0,
                      index: 0,
                    });
                  }}
                >
                  Save Best Answer
                </button>
                <button
                  style={{
                    backgroundColor: "lightgray",
                    borderRadius: 5,
                    marginLeft: 10,
                    padding: 5,
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    // setFileUrl({ pdf: "" });
                    saveBestAnswer(getData?.result?.bestAnswers?.[0]._id, {
                      bestAnswer: false,
                      copy: values.copy0,
                      index: 0,
                    });
                  }}
                >
                  Reject Best Answer
                </button>
                <FormControl style={{ marginLeft: "20px" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          getData?.result?.bestAnswers?.[0]?.bestAnswer
                            ? true
                            : false
                        }
                        name="isBestAnswer"
                      />
                    }
                    label="Best Answer"
                  />
                </FormControl>
              </Box>
              <Box style={{ marginTop: "40px" }} className="uploadBox-form">
                <p style={{ marginRight: "20px" }}>Best Answer 2:*</p>
                {Boolean(id) &&
                  Boolean(
                    getData?.result?.bestAnswers?.[1]?.evaluatedPdfUrl
                  ) && (
                    <Tooltip title="Open Uploaded File" arrow>
                      <IconButton aria-label="open">
                        <OpenInBrowserIcon
                          color="secondary"
                          onClick={() =>
                            window.open(
                              `${AWS_URL}${
                                getData?.result?.bestAnswers?.[1]?.copy ??
                                getData?.result?.bestAnswers?.[1]
                                  ?.evaluatedPdfUrl ??
                                ""
                              }`,
                              "_blank"
                            )
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                <FileUploader
                  setFileUrl={(url) =>
                    // setFileUrls({ ...fileUrls, questionPdfUrl: url })
                    (values.copy1 = url)
                  }
                />
                {Boolean(
                  getData?.result?.bestAnswers?.[1]?.evaluatedPdfUrl
                ) && (
                  <Tooltip title="Open Evaluated Pdf" arrow>
                    <IconButton aria-label="open">
                      <OpenInBrowserIcon
                        color="secondary"
                        onClick={() =>
                          window.open(
                            `${AWS_URL}${
                              getData?.result?.bestAnswers?.[1]
                                ?.evaluatedPdfUrl ?? ""
                            }`,
                            "_blank"
                          )
                        }
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {Boolean(getData?.result?.bestAnswers?.[1]?.answerPdfUrl) && (
                  <Tooltip title="Open Answer Pdf" arrow>
                    <IconButton aria-label="open">
                      <OpenInBrowserIcon
                        color="secondary"
                        onClick={() =>
                          window.open(
                            `${AWS_URL}${
                              getData?.result?.bestAnswers?.[1]?.answerPdfUrl ??
                              ""
                            }`,
                            "_blank"
                          )
                        }
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <button
                  style={{
                    backgroundColor: "lightgray",
                    borderRadius: 5,
                    marginLeft: 10,
                    padding: 5,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    // setFileUrl({ pdf: "" });
                    saveBestAnswer(getData?.result?.bestAnswers?.[1]._id, {
                      bestAnswer: true,
                      copy: values.copy1,
                      index: 1,
                    });
                  }}
                >
                  Save Best Answer
                </button>
                <button
                  style={{
                    backgroundColor: "lightgray",
                    borderRadius: 5,
                    marginLeft: 10,
                    padding: 5,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    // setFileUrl({ pdf: "" });
                    saveBestAnswer(getData?.result?.bestAnswers?.[1]._id, {
                      bestAnswer: false,
                      copy: values.copy1,
                      index: 1,
                    });
                  }}
                >
                  Reject Best Answer
                </button>
                <FormControl style={{ marginLeft: "20px" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          getData?.result?.bestAnswers?.[1]?.bestAnswer
                            ? true
                            : false
                        }
                        name="isBestAnswer"
                      />
                    }
                    label="Best Answer"
                  />
                </FormControl>
              </Box>
              <Box style={{ marginTop: "40px" }} className="uploadBox-form">
                <p style={{ marginRight: "20px" }}>Best Answer 3:*</p>
                {Boolean(id) &&
                  Boolean(
                    getData?.result?.bestAnswers?.[2]?.evaluatedPdfUrl
                  ) && (
                    <Tooltip title="Open Uploaded File" arrow>
                      <IconButton aria-label="open">
                        <OpenInBrowserIcon
                          color="secondary"
                          onClick={() =>
                            window.open(
                              `${AWS_URL}${
                                getData?.result?.bestAnswers?.[2]?.copy ??
                                getData?.result?.bestAnswers?.[2]
                                  ?.evaluatedPdfUrl ??
                                ""
                              }`,
                              "_blank"
                            )
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                <FileUploader
                  setFileUrl={(url) =>
                    // setFileUrls({ ...fileUrls, questionPdfUrl: url })
                    (values.copy2 = url)
                  }
                />
                {Boolean(
                  getData?.result?.bestAnswers?.[2]?.evaluatedPdfUrl
                ) && (
                  <Tooltip title="Open Evaluated Pdf" arrow>
                    <IconButton aria-label="open">
                      <OpenInBrowserIcon
                        color="secondary"
                        onClick={() =>
                          window.open(
                            `${AWS_URL}${
                              getData?.result?.bestAnswers?.[2]
                                ?.evaluatedPdfUrl ?? ""
                            }`,
                            "_blank"
                          )
                        }
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {Boolean(getData?.result?.bestAnswers?.[2]?.answerPdfUrl) && (
                  <Tooltip title="Open Answer Pdf" arrow>
                    <IconButton aria-label="open">
                      <OpenInBrowserIcon
                        color="secondary"
                        onClick={() =>
                          window.open(
                            `${AWS_URL}${
                              getData?.result?.bestAnswers?.[2]?.answerPdfUrl ??
                              ""
                            }`,
                            "_blank"
                          )
                        }
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <button
                  style={{
                    backgroundColor: "lightgray",
                    borderRadius: 5,
                    marginLeft: 10,
                    padding: 5,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    // setFileUrl({ pdf: "" });
                    saveBestAnswer(getData?.result?.bestAnswers?.[2]._id, {
                      bestAnswer: true,
                      copy: values.copy2,
                      index: 2,
                    });
                  }}
                >
                  Save Best Answer
                </button>
                <button
                  style={{
                    backgroundColor: "lightgray",
                    borderRadius: 5,
                    marginLeft: 10,
                    padding: 5,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    // setFileUrl({ pdf: "" });
                    saveBestAnswer(getData?.result?.bestAnswers?.[2]._id, {
                      bestAnswer: false,
                      copy: values.copy2,
                      index: 2,
                    });
                  }}
                >
                  Reject Best Answer
                </button>
                <FormControl style={{ marginLeft: "20px" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          getData?.result?.bestAnswers?.[2]?.bestAnswer
                            ? true
                            : false
                        }
                        name="isBestAnswer"
                      />
                    }
                    label="Best Answer"
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl component="fieldset" style={{ marginTop: "20px" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.showBestAnswers}
                          onChange={handleChange}
                          name="showBestAnswers"
                        />
                      }
                      label="Show best Answers"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box style={{ marginTop: "40px" }}>
                <TextField
                  error={Boolean(touched.vdoId && errors.vdoId)}
                  helperText={touched.vdoId && errors.vdoId}
                  label="vdo Id"
                  margin="normal"
                  name="vdoId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.vdoId}
                  variant="outlined"
                />
              </Box>
              <Box style={{ marginTop: "50px" }}>
                <h4 style={{ marginBottom: "10px" }}>Syllabus:</h4>
                <QuillTextEditor
                  setHtmlData={setTextEditorData}
                  htmlData={getData?.result?.syllabus}
                />
              </Box>
            </div>
            <Box style={{ marginTop: "50px" }}>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{ marginRight: "20px" }}
              >
                {id ? "Update Question" : "Create Question"}
              </Button>
              <Link to="/questions">
                <Button
                  size="large"
                  variant="contained"
                  className={clsx(
                    "passiveButton",
                    "cancelButton",
                    "passiveButtonHover"
                  )}
                >
                  Cancel
                </Button>
              </Link>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error. Something went wrong.!
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default AddQuestions;
