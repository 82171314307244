import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useSWR from "swr";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import Table from "../../components/Commons/Table";
import LinearLoading from "../../components/Commons/LinearLoading";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";

interface ContactProps {}

const Contact: React.FC<ContactProps> = ({}) => {
  const { data, error } = useSWR(`/contact`, fetcher, staticOptions);
  const [openError, setOpenError] = useState(false);

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    console.log(error.info);
    setOpenError(true);
  }, [error]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  return (
    <div>
      <Link to="/contact/addContact">
        <div
          className={clsx(
            "addMemberButton",
            "buttonEffectShadow",
            "activeButton"
          )}
          style={{ width: "140px" }}
        >
          <LibraryAddIcon />
          Add Contact
        </div>
      </Link>
      {!!!data ? (
        <LinearLoading />
      ) : (
        <Table
          data={data?.result}
          uniqueId={"_id"}
          enableEdit={true}
          enableDelete={true}
          navTo={"contact/editContact"}
          apiEndpoint={"contact"}
          dataOrder={["_id", "type"]}
        />
      )}
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while fetching Contact.
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default Contact;
