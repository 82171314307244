import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useSWR from "swr";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import Table from "../../components/Commons/Table";
import LinearLoading from "../../components/Commons/LinearLoading";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import TimeField from "../../components/Commons/TimeField";
import Filter from "../../components/Commons/Filter";

interface EnquiryProps {}

const Enquiry: React.FC<EnquiryProps> = ({}) => {
  const [queryString, setQueryString] = useState<string>(``);

  const { data, error } = useSWR(
    `/enquiry?${queryString}`,
    fetcher,
    staticOptions
  );

  //   const [tableData, setTableData] = useState([]);
  const [openError, setOpenError] = useState(false);
  function timeField(date: string) {
    return <TimeField date={date} showTime={true} />;
  }

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    console.log(error.info);
    setOpenError(true);
  }, [error]);

  useEffect(() => {
    if (data?.result?.length) {
      for (let i in data.result) {
        data.result[i].createdAt = timeField(data.result[i].createdAt);
        data.result[i].subscriptionId = data.result[i].subscriptionTypeSlugId;
        data.result[i].isUpscAppeared =
          data.result[i].isUpscAppeared?.toString();
      }
      //   setTableData(data.result);
    }
  }, [data]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const filters = [
    {
      name: "Type",
      filterType: "dropDown",
      options: [{ label: "Lead", id: "LEAD" }],

      defaultValue: { label: "", id: "" },
      param: "type",
    },
  ];

  return (
    <div>
      {/* <Link to="/enquiry/addenquiry">
        <div
          className={clsx(
            "addMemberButton",
            "buttonEffectShadow",
            "activeButton"
          )}
          style={{ width: "140px" }}
        >
          <LibraryAddIcon />
          Add enquiry
        </div>
      </Link> */}
      <Filter
        filters={filters}
        setQueryString={(query) => {
          setQueryString(query);
          //   setPage(1);
        }}
      />
      {!!!data ? (
        <LinearLoading />
      ) : (
        <Table
          data={data?.result}
          //   uniqueId={"email"}
          enableEdit={false}
          enableDelete={false}
          //   navTo={"enquiry/editenquiry"}
          apiEndpoint={"enquiry"}
          dataOrder={[
            "email",
            "name",
            "mobile",
            "city",
            "type",
            "subscriptionId",
            "course",
            "isUpscAppeared",
            "createdAt",
          ]}
        />
      )}
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while fetching enquiry.
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default Enquiry;
