import React, { useEffect, useState } from "react";
import MultiSelect from "react-select";
import makeAnimated from "react-select/animated";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { fetcher } from "../../utils/Functions";
import { OPTIONALS, PCS, staticOptions } from "../../utils/Constants";
import useSWR from "swr";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import { useParams } from "react-router-dom";

interface AddEditModuleProps {}

const animatedComponents = makeAnimated();

const AddEditModule: React.FC<AddEditModuleProps> = ({}) => {
  const [openError, setOpenError] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState(null);
  const [moduleData, setModuleData] = useState({});

  let { id } = useParams();
  const navigate = useNavigate();

  //For GET Request to get all the Sunscriptions
  const { data: subscriptionsData, error: subscriptionsError } = useSWR(
    `/subscriptionType`,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(subscriptionsError) || !Boolean(subscriptionsError?.status))
      return;
    console.log(subscriptionsError.info);
    setOpenError(true);
  }, [subscriptionsError]);

  useEffect(() => {
    if (!Boolean(subscriptionsData) || Boolean(subscriptionsData?.error))
      return;
    const dropdownData: any = [];
    subscriptionsData?.result?.forEach((item) => {
      let option = { value: item._id, label: item.name };
      dropdownData.push(option);
    });
    setSubscriptions(dropdownData);
  }, [subscriptionsData]);

  // For POST Request
  const { data: postData, error: postError } = useSWR(
    !Boolean(id) ? [`/modules`, `post`, moduleData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(postError) || !Boolean(postError?.status)) return;
    console.log(postError.info);
    setOpenError(true);
  }, [postError]);

  useEffect(() => {
    if (!Boolean(postData) || Boolean(postData?.error)) return;
    navigate("..", { replace: true });
  }, [postData]);

  //for GET Request to get module details. Depends on id parameter
  const { data: getData, error: getError } = useSWR(
    Boolean(id) ? `/modules/${id}?subscriptions=true` : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(getError) || !Boolean(getError?.status)) return;
    console.log(getError.info);
    setOpenError(true);
  }, [getError]);

  useEffect(() => {
    if (!Boolean(getData) || Boolean(getData?.error)) return;
    let modifiedModules: any = [];
    getData?.result?.subscriptions?.forEach((item) => {
      const subscription = { value: item._id, label: item.name };
      modifiedModules.push(subscription);
    });
    if (modifiedModules.length) {
      setSelectedOption(modifiedModules);
      setSelectedSubscriptions(modifiedModules);
    }
  }, [getData]);

  //For PUT Request. Depends on the id param
  const { data: putData, error: putError } = useSWR(
    Boolean(id) ? [`/modules/${id}`, `put`, moduleData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(putError) || !Boolean(putError?.status)) return;
    console.log(putError.info);
    setOpenError(true);
  }, [putError]);

  useEffect(() => {
    if (!Boolean(putData) || Boolean(putData?.error)) return;
    navigate("../..", { replace: true });
  }, [putData]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: getData?.result?.name ?? "",
          description: getData?.result?.description ?? "",
          type: getData?.result?.type ?? "",
          section: getData?.result?.section ?? "",
          optional: getData?.result?.optional ?? "",
          pcs: getData?.result?.pcs ?? "",
          year: getData?.result?.year ?? "",
          order: "",
          isVisible: getData?.result?.isVisible ?? false,
          isActive: getData?.result?.isActive ?? false,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255)
            .required("Notes Library Name is required"),
          section: Yup.string().required("Section is required"),
        })}
        onSubmit={(values, actions) => {
          let subscriptions: any = [];
          selectedOption?.forEach((item) => {
            subscriptions.push(item.value);
          });
          setModuleData({ ...values, subscriptions });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              backgroundColor: "white",
              padding: "30px",
              borderRadius: "15px",
            }}
          >
            <Box>
              <Typography
                color="textPrimary"
                variant="h4"
                style={{ marginBottom: "20px" }}
              >
                {id ? "Edit Module:" : "Create a new Module:"}
              </Typography>
            </Box>
            <div className="form-container">
              <TextField
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Module Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
                required
              />
              <TextField
                label="Description"
                multiline
                rows={2}
                rowsMax={4}
                margin="normal"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                value={values.description}
              />
              <Box style={{ marginTop: "20px", cursor: "pointer", zIndex: 10 }}>
                {Boolean(selectedSubscriptions) && (
                  <MultiSelect
                    options={subscriptions}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Subscriptions"
                    components={animatedComponents}
                    onChange={setSelectedOption}
                    defaultValue={selectedSubscriptions}
                  />
                )}
                {!Boolean(selectedSubscriptions) && (
                  <MultiSelect
                    options={subscriptions}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Subscriptions"
                    components={animatedComponents}
                    onChange={setSelectedOption}
                  />
                )}
              </Box>
              <Box style={{ marginTop: "30px" }}>
                <Grid container spacing={3}>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      className="dropdown-form"
                      required
                    >
                      <InputLabel>Section</InputLabel>
                      <Select
                        onChange={handleChange}
                        label="Section"
                        name="section"
                        value={values.section}
                      >
                        <MenuItem value="notes">Notes</MenuItem>
                        <MenuItem value="tests">Tests</MenuItem>
                        <MenuItem value="combos">Combos</MenuItem>
                        <MenuItem value="videos">Videos</MenuItem>
                        <MenuItem value="offline">Offline</MenuItem>
                        <MenuItem value="pcs">PCS</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" className="dropdown-form">
                      <InputLabel>Type</InputLabel>
                      <Select
                        onChange={handleChange}
                        label="Type"
                        name="type"
                        value={values.type}
                      >
                        <MenuItem value="prelims">Prelims</MenuItem>
                        <MenuItem value="mains">Mains</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" className="dropdown-form">
                      <InputLabel>Optional</InputLabel>
                      <Select
                        onChange={handleChange}
                        label="Optional"
                        name="optional"
                        value={values.optional}
                      >
                        {OPTIONALS.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" className="dropdown-form">
                      <InputLabel>PCS</InputLabel>
                      <Select
                        onChange={handleChange}
                        label="Pcs"
                        name="pcs"
                        value={values.pcs}
                      >
                        {PCS.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" className="dropdown-form">
                      <InputLabel>Year</InputLabel>
                      <Select
                        onChange={handleChange}
                        label="Year"
                        name="year"
                        value={values.year}
                      >
                        <MenuItem value={2021}>2021</MenuItem>
                        <MenuItem value={2022}>2022</MenuItem>
                        <MenuItem value={2023}>2023</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <FormControl component="fieldset" style={{ marginTop: "20px" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isVisible}
                          onChange={handleChange}
                          name="isVisible"
                        />
                      }
                      label="Is Visible"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isActive}
                          onChange={handleChange}
                          name="isActive"
                        />
                      }
                      label="Is Active"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            </div>
            <Box style={{ marginTop: "50px" }}>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{ marginRight: "20px" }}
              >
                {id ? "Update Module" : "Create Module"}
              </Button>
              <Link to="/modules">
                <Button
                  size="large"
                  variant="contained"
                  className={clsx(
                    "passiveButton",
                    "cancelButton",
                    "passiveButtonHover"
                  )}
                >
                  Cancel
                </Button>
              </Link>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error. Something went wrong.!
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default AddEditModule;
