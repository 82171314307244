import queryString from "query-string";
import axios from "axios";
import Cookie from "js-cookie";
import _ from "lodash";
import { API_URL } from "./Constants";
import toast from "react-hot-toast";

interface InputParameters {
  fieldId?: string;
  isRequired: boolean;
  inputType: any;
  value: any;
}

axios.defaults.headers.common["type"] = "WEB";
axios.defaults.headers.common["x-client-tenant-type"] = "INTERNAL";
axios.defaults.headers.common["accessToken"] = Cookie.get("accessToken");
// axios.defaults.headers.common["userId"] = Cookie.get("userId");

export const fetcher = async (
  url: string,
  method: any = "get",
  data: any = {}
) => {
  axios.defaults.baseURL = API_URL;

  if (url === "/upload" && data?.has("fileData")) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
  } else if (url === "/upload/presigned" && _.isEmpty(data)) return;
  else if (
    (method?.toLowerCase() === "post" || method?.toLowerCase() === "put") &&
    _.isEmpty(data)
  )
    return;

  return axios({ url: url, method: method, data: data })
    .then((res) => res.data)
    .catch((err) => {
      const error: any = new Error(
        `An error occurred while fetching the data. ${err?.response?.data?.msg}`
      );
      error.info = err?.response?.data?.msg;
      error.status = err?.response?.status;
      throw error;
    });
};

export const customFetcher = async (
  baseUrl: string = API_URL,
  method: any,
  data: any = {}
) => {
  axios.defaults.baseURL = baseUrl;

  if (
    (method?.toLowerCase() === "post" || method?.toLowerCase() === "put") &&
    _.isEmpty(data) &&
    !data?.has("file")
  )
    return;

  return axios({ url: "", method: method, data: data })
    .then((res) => {
      if (res.status === 204) {
        return "204";
      }
      return res.data;
    })
    .catch((err) => {
      const error: any = new Error(
        `An error occurred while fetching the data. ${err?.response?.data?.msg}`
      );
      error.info = err?.response?.data?.msg;
      error.status = err?.response?.status;
      throw error;
    });
};

//Key should be the query param from the URL you want
export const getQueryParam = (key: string) => {
  const value = queryString.parse(window.location.search);
  if (Boolean(value)) {
    return value[key];
  }
  return false;
};

export const inputValidator = (inputParameters: InputParameters) => {
  if (inputParameters.isRequired && !Boolean(inputParameters.value?.trim()))
    return false;
  return true;
};

export function isAuthenticated() {
  if (Boolean(Cookie.get("accessToken")) && Boolean(Cookie.get("userId")))
    return true;
  return false;
}

export const getUserRoles: any = {
  ...(Cookie.get("roles") && {
    isAdmin: JSON.parse(Cookie.get("roles") ?? "")?.isAdmin,
    isCustomer: JSON.parse(Cookie.get("roles") ?? "")?.isCustomer,
    isEmployee: JSON.parse(Cookie.get("roles") ?? "")?.isEmployee,
    isEvaluator: JSON.parse(Cookie.get("roles") ?? "")?.isEvaluator,
  }),
};

export const getEmployeeDashboards = Cookie.get("dashboards")
  ? JSON.parse(Cookie.get("dashboards") ?? "")
  : [];

export function getFormatedDate(data: string | Date) {}

export const isAuthorized = (id: string) => {
  if (getUserRoles.isAdmin) return true;
  if (getUserRoles.isEmployee && getEmployeeDashboards.indexOf(id) > -1) {
    return true;
  }
  return false;
};

export const fileNameShortner = (file: string) => {
  let arr = file?.split(".");
  let fileName = arr[0];
  let fileExtension = arr[arr.length - 1];
  if (fileName.length > 10) {
    fileName = fileName.substring(0, 10) + "..";
  }
  return fileName + "." + fileExtension;
};

export const successToast = (
  msg: string = "Successful.",
  duration: number = 4000
) =>
  toast.success(msg, {
    duration: duration,
    position: "bottom-center",
    style: {
      borderRadius: "10px",
      background: "#333",
      color: "#fff",
    },
  });

export const errorToast = (msg: string = "Error. Please try later.") =>
  toast.error(msg, {
    duration: 4000,
    position: "bottom-center",
    style: {
      borderRadius: "10px",
      background: "#333",
      color: "#fff",
    },
  });

export const loadingToast = (msg: string = "Please wait...") =>
  toast.loading(msg, {
    position: "bottom-center",
    style: {
      borderRadius: "10px",
      background: "#333",
      color: "#fff",
    },
  });
