import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useSWR from "swr";
import { fetcher } from "../../utils/Functions";
import { staticOptions, AWS_URL } from "../../utils/Constants";
import { FilePlus } from "react-feather";
import Table from "../../components/Commons/Table";
import LinearLoading from "../../components/Commons/LinearLoading";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import LaunchIcon from "@material-ui/icons/Launch";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

interface NotesProps {}

const Notes: React.FC<NotesProps> = ({}) => {
  const [openError, setOpenError] = useState(false);
  const { data, error } = useSWR(`/notes`, fetcher, staticOptions);
  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    console.log(error.info);
    setOpenError(true);
  }, [error]);

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;
    data?.result.forEach((item) => {
      item.topicwiseDownload = Boolean(item.topicwiseDownload)
        ? getLinkIcon(item.topicwiseDownload)
        : null;
      item.sourcewiseDownload = Boolean(item.sourcewiseDownload)
        ? getLinkIcon(item.sourcewiseDownload)
        : null;
      item.primaryDownload = Boolean(item.primaryDownload)
        ? getLinkIcon(item.primaryDownload)
        : null;
      item.isVisible = item.isVisible.toString();
      item.isActive = item.isActive.toString();
      item.isFree = item.isFree.toString();
      item.isWatermarked = item.isWatermarked.toString();
    });
    setTableData(data.result);
  }, [data]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  function getLinkIcon(url: string) {
    return (
      <Tooltip title="Open File" arrow>
        <IconButton
          aria-label="open"
          onClick={() => window.open(`${AWS_URL}${url}`, "_blank")}
        >
          <LaunchIcon color="action" />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <div>
      <Link to="/notes/addNotes">
        <div
          className={clsx(
            "addMemberButton",
            "buttonEffectShadow",
            "activeButton"
          )}
        >
          <FilePlus size={"20px"} />
          Add Notes
        </div>
      </Link>
      {!!!tableData ? (
        <LinearLoading />
      ) : (
        <Table
          data={tableData}
          uniqueId={"id"}
          enableEdit={true}
          enableDelete={true}
          navTo={"notes/editNotes"}
          apiEndpoint={"notes"}
          dataOrder={[
            "name",
            "id",
            "type",
            "year",
            "topicwiseDownload",
            "sourcewiseDownload",
            "primaryDownload",
            "totalDownloads",
            "vdoId",
            "isVisible",
            "isActive",
            "isFree",
            "isWatermarked",
          ]}
        />
      )}
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while fetching Notes.
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default Notes;
