import React, { useEffect, useState } from "react";
// import MultiSelect from "react-select";
import makeAnimated from "react-select/animated";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  //   TextField,
  Typography,
  //   Select,
  //   MenuItem,
  //   FormControl,
  //   InputLabel,
  //   Switch,
  //   FormGroup,
  //   FormControlLabel,
} from "@material-ui/core";
// import Grid from "@material-ui/core/Grid";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import useSWR from "swr";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import QuillTextEditor from "../../components/Commons/QuillTextEditor";

interface AddEditContactProps {}

const animatedComponents = makeAnimated();

const AddEditContact: React.FC<AddEditContactProps> = ({}) => {
  const [openError, setOpenError] = useState(false);
  const [contactData, setContactData] = useState({});
  const [content, setContent] = useState<string>("");

  let { id } = useParams();
  const navigate = useNavigate();

  // For POST Request
  const { data: postData, error: postError } = useSWR(
    !Boolean(id) ? [`/contact`, `post`, contactData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(postError) || !Boolean(postError?.status)) return;
    console.log(postError.info);
    setOpenError(true);
  }, [postError]);

  useEffect(() => {
    if (!Boolean(postData) || Boolean(postData?.error)) return;
    navigate("..", { replace: true });
  }, [postData]);

  //for GET Request to get contact details. Depends on id parameter
  const { data: getData, error: getError } = useSWR(
    Boolean(id) ? `/contact/${id}` : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(getError) || !Boolean(getError?.status)) return;
    console.log(getError.info);
    setOpenError(true);
  }, [getError]);

  //For PUT Request. Depends on the id param
  const { data: putData, error: putError } = useSWR(
    Boolean(id) ? [`/contact/${id}`, `put`, contactData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(putError) || !Boolean(putError?.status)) return;
    console.log(putError.info);
    setOpenError(true);
  }, [putError]);

  useEffect(() => {
    if (!Boolean(putData) || Boolean(putData?.error)) return;
    navigate("../..", { replace: true });
  }, [putData]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const setContentEditorData = (data: string) => {
    setContent(data);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{ type: getData?.result?.type ?? "" }}
        onSubmit={(values, actions) => {
          setContactData({ ...values, ...(Boolean(content) && { content }) });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              backgroundColor: "white",
              padding: "30px",
              borderRadius: "15px",
            }}
          >
            <Box>
              <Typography
                color="textPrimary"
                variant="h4"
                style={{ marginBottom: "20px" }}
              >
                {id ? "Edit Contact:" : "Create a new Contact:"}
              </Typography>
            </Box>
            <div className="form-container">
              <Box style={{ marginTop: "50px" }}>
                <h4 style={{ marginBottom: "10px" }}>Content:</h4>
                <FormControl
                  variant="outlined"
                  className="dropdown-form"
                  style={{ marginBottom: "10px" }}
                >
                  <InputLabel>Type</InputLabel>
                  <Select
                    onChange={handleChange}
                    label="Type"
                    name="type"
                    value={values.type}
                  >
                    <MenuItem value={"CONTACT"}>CONTACT</MenuItem>
                    <MenuItem value={"APPLY"}>APPLY</MenuItem>
                  </Select>
                </FormControl>
                <QuillTextEditor
                  setHtmlData={setContentEditorData}
                  htmlData={getData?.result?.content}
                />
              </Box>
            </div>
            <Box style={{ marginTop: "50px" }}>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{ marginRight: "20px" }}
              >
                {id ? "Update Contact" : "Create Contact"}
              </Button>
              <Link to="/contacts">
                <Button
                  size="large"
                  variant="contained"
                  className={clsx(
                    "passiveButton",
                    "cancelButton",
                    "passiveButtonHover"
                  )}
                >
                  Cancel
                </Button>
              </Link>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error. Something went wrong.!
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default AddEditContact;
