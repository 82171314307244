import React, { useEffect, useState } from "react";
import Table from "../../components/Commons/Table";
import useSWR from "swr";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import LinearLoading from "../../components/Commons/LinearLoading";
import TimeField from "../../components/Commons/TimeField";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { ShoppingCart } from "react-feather";
import Filter from "../../components/Commons/Filter";
import FileDownload from "js-file-download";
import { Button } from "@material-ui/core";

interface CustomerSubcriptionsProps {}

const Orders: React.FC<CustomerSubcriptionsProps> = ({}) => {
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [queryString, setQueryString] = useState<string>(``);

  const { data } = useSWR(
    `/userSubscription/books/?limit=${limit}&page=${page}${queryString}`,
    fetcher,
    staticOptions
  );

  const { data: subscriptions } = useSWR(
    `/userSubscription/subscriptionType`,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;
    data?.result?.docs?.forEach((item) => {
      item.startDate = timeField(item.startDate);
    });
  }, [data]);

  function timeField(date: string) {
    return <TimeField date={date} showTime={true} />;
  }

  function download() {
    fetcher(
      `/userSubscription/books/download/?limit=${
        data?.result?.pagination?.totalDocs
      }&page=${1}${queryString}`
    )
      .then((res) => {
        console.log("response", res);
        FileDownload(res, "report.csv");
      })
      .catch((e) => console.log(e));
  }

  const filters = [
    {
      name: "Email",
      filterType: "textField",
      options: [],
      defaultValue: "",
      param: "email",
    },
    {
      name: "Subscription",
      filterType: "dropDown",
      options: subscriptions?.result?.map((item) => {
        return { label: item.name, id: item._id };
      }),
      defaultValue: { label: "", id: "" },
      param: "subscriptionTypeId",
    },
    {
      name: "From Date (Including)",
      filterType: "date",
      // options:[],
      // defaultValue:"",
      param: "startDate",
    },
    {
      name: "To Date (Excluding)",
      filterType: "date",
      // options:[],
      // defaultValue:"",
      param: "endDate",
    },
  ];
  return (
    <div>
      <Filter
        filters={filters}
        setQueryString={(query) => {
          setQueryString(query);
          setPage(1);
        }}
      />
      {!!!data?.result ? (
        <LinearLoading />
      ) : (
        <>
          <Table
            data={data?.result?.data}
            dataOrder={[
              "email",
              "uid",
              "products",
              "amount",
              "date",
              "name",
              "phone",
              "address",
              "landmark",
              "city",
              "state",
              "pincode",
            ]}
            // uniqueId={"email"}
            // enableDelete={true}
            // apiEndpoint={"userSubscription"}
            // setLimit={setLimit}
            // setPage={setPage}
            // count={data?.result?.totalDocs}
            // currentPage={data?.result?.page}
            pagination={true}
            setLimit={setLimit}
            setPage={setPage}
            count={data?.result?.pagination?.totalDocs}
            currentPage={data?.result?.pagination?.page}
          />
        </>
      )}
      <Button
        style={{ margin: 20, width: "auto" }}
        className={clsx("activeButton", "addButton", "activeButtonHover")}
        onClick={download}
      >
        <p style={{ padding: 10 }}>Download</p>
      </Button>
    </div>
  );
};

export default Orders;
