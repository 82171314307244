import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import "../../assets/style/Notes.css";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import useSWR from "swr";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import cryptoRandomString from "crypto-random-string";
import { dashboards } from "../../utils/DashboardItems";

interface AddEditUserProps {}

interface evaluatorData {
  name: string;
  email: string;
  password: string;
  phone: string;
  //   isAdmin: boolean;
  //   isCustomer: boolean;
  isEvaluator: boolean;
  //   isEmployee: boolean;
  //   dashboards: string[];
}

const AddEditUser: React.FC<AddEditUserProps> = ({}) => {
  const [userData, setUserData] = useState<evaluatorData>();
  const [openError, setOpenError] = useState(false);

  let { id } = useParams();
  const navigate = useNavigate();
  const generatedPassword = cryptoRandomString({ length: 8 });

  const { data, error } = useSWR(
    !Boolean(id) ? [`/auth/signup`, `post`, userData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;
    navigate("/onBoarding", { replace: true });
  }, [data]);

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    console.log(error.info);
    setOpenError(true);
  }, [error]);

  const { data: getMember, error: getMemberError } = useSWR(
    Boolean(id) ? [`/users/onBoarding/${id}`, `get`] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(getMemberError) || !Boolean(getMemberError?.status)) return;
    console.log(getMemberError.info);
    setOpenError(true);
  }, [getMemberError]);

  const { data: putData, error: putError } = useSWR(
    Boolean(id) ? [`/users/onBoarding/${id}`, `put`, userData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(putData) || Boolean(putData?.error)) return;
    navigate("/onBoarding", { replace: true });
  }, [putData]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: getMember?.result?.name ?? "",
          email: getMember?.result?.email ?? "",
          password: generatedPassword,
          confirmPassword: generatedPassword,
          phone: getMember?.result?.phone ?? "",
          //   isAdmin: getMember?.result?.isAdmin ?? false,
          //   isCustomer: getMember?.result?.isCustomer ?? true,
          isEvaluator: getMember?.result?.isEvaluator ?? false,
          //   isEmployee: getMember?.result?.isEmployee ?? false,
          dashboards: getMember?.result?.dashboards ?? [],
          changePassword: false,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Name cannot be empty"),
          email: Yup.string()
            .email("Looks like this is not an email")
            .required("Email cannot be empty"),
          password: Yup.string()
            .required("Password cannot be empty")
            .test(
              "len",
              "Password must be at least 8 characters.",
              (val) => val?.length!! >= 8
            ),
          confirmPassword: Yup.string()
            .required("Confirm Password cannot be empty")
            .when("password", {
              is: (val) => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf(
                [Yup.ref("password")],
                "Both passwords need to be the same"
              ),
            }),
          phone: Yup.number()
            .typeError("That doesn't look like a Phone number")
            .required("Phone number cannot be empty"),
        })}
        onSubmit={(values, actions) => {
          const payload: evaluatorData = {
            name: values.name,
            email: values.email,
            password: values.changePassword ? values.password : "",
            phone: values.phone,
            // isAdmin: values.isAdmin,
            // isCustomer: values.isCustomer,
            isEvaluator: values.isEvaluator,
            // isEmployee: values.isEmployee,
            // dashboards: values.dashboards,
          };
          setUserData(payload);
          // console.log(values.dashboards);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              backgroundColor: "white",
              padding: "30px",
              borderRadius: "15px",
            }}
          >
            <Box>
              <Typography
                color="textPrimary"
                variant="h4"
                style={{ marginBottom: "20px" }}
              >
                {id ? "Edit User:" : "Create a new User:"}
              </Typography>
            </Box>
            <div className="form-container">
              <TextField
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="User Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
                required
                disabled={Boolean(id)}
              />
              <TextField
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                label="Email"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                variant="outlined"
                required
                disabled={Boolean(id)}
              />
              <Box>
                <FormControl component="fieldset" style={{ marginTop: "20px" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.changePassword}
                          onChange={handleChange}
                          name="changePassword"
                        />
                      }
                      label="Add/Change Password"
                    />
                  </FormGroup>
                  {values.changePassword && (
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      variant="outlined"
                      required={values.changePassword}
                      // disabled={values.changePassword}
                    />
                  )}
                  {values.changePassword && (
                    <TextField
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      label="Confirm Password"
                      margin="normal"
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirmPassword}
                      variant="outlined"
                      required={values.changePassword}
                      // disabled={values.changePassword}
                    />
                  )}
                </FormControl>
              </Box>

              <TextField
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
                label="Phone Number"
                margin="normal"
                name="phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                variant="outlined"
                required
              />

              <Box>
                <FormControl component="fieldset" style={{ marginTop: "20px" }}>
                  <FormGroup>
                    {/* <FormControlLabel
                      control={
                        <Switch
                          checked={values.isAdmin}
                          onChange={handleChange}
                          name="isAdmin"
                        />
                      }
                      label="Is Admin"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isCustomer}
                          onChange={handleChange}
                          name="isCustomer"
                        />
                      }
                      label="Is Customer"
                    /> */}
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isEvaluator}
                          onChange={handleChange}
                          name="isEvaluator"
                        />
                      }
                      label="Is Evaluator"
                    />
                    {/* <FormControlLabel
                      control={
                        <Switch
                          checked={values.isEmployee}
                          onChange={handleChange}
                          name="isEmployee"
                        />
                      }
                      label="Is Employee"
                    /> */}
                  </FormGroup>
                </FormControl>
              </Box>
              {/* {Boolean(values.isEmployee) && (
                <div
                  role="group"
                  aria-labelledby="checkbox-group"
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <p
                    style={{ marginRight: "20px", textDecoration: "underline" }}
                  >
                    Select dashboards for employee:
                  </p>
                  {dashboards.map((item) => (
                    <label>
                      <Field
                        type="checkbox"
                        name="dashboards"
                        value={item.id}
                        style={{ margin: "10px", cursor: "pointer" }}
                      />
                      {item.title}
                    </label>
                  ))}
                </div>
              )} */}
            </div>
            <Box style={{ marginTop: "50px" }}>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{ marginRight: "20px" }}
              >
                {id ? "Update User" : "Create User"}
              </Button>
              <Link to="/onBoarding">
                <Button
                  size="large"
                  variant="contained"
                  className={clsx(
                    "passiveButton",
                    "cancelButton",
                    "passiveButtonHover"
                  )}
                >
                  Cancel
                </Button>
              </Link>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error. {error?.info}
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default AddEditUser;
