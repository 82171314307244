import React, { useEffect, useState } from "react";
import Table from "../../components/Commons/Table";
import useSWR from "swr";
import {
  errorToast,
  fetcher,
  getUserRoles,
  loadingToast,
  successToast,
} from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import LinearLoading from "../../components/Commons/LinearLoading";
import TimeField from "../../components/Commons/TimeField";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { ShoppingCart } from "react-feather";
import Filter from "../../components/Commons/Filter";
import toast, { Toaster } from "react-hot-toast";

interface CustomerSubcriptionsProps {}

const CustomerSubcriptions: React.FC<CustomerSubcriptionsProps> = ({}) => {
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [queryString, setQueryString] = useState<string>(``);

  const { data } = useSWR(
    getUserRoles.isAdmin
      ? `/userSubscription/?limit=${limit}&page=${page}${queryString}`
      : null,
    fetcher,
    staticOptions
  );

  const { data: subscriptions } = useSWR(
    `/subscriptionType`,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;
    data?.result?.docs?.forEach((item) => {
      item.startDate = timeField(item.startDate);
    });
  }, [data]);

  const handleSendData = async () => {
    if (!queryString) {
      toast.dismiss();
      errorToast("Select subsctription");
      return;
    }
    loadingToast();
    fetcher(`/userSubscription/sendMail/?${queryString}`, "get")
      .then(() => {
        toast.dismiss();
        successToast("Data sent");
      })
      .catch(() => {
        toast.dismiss();
        errorToast("Error");
      });
    return;
  };

  function timeField(date: string) {
    return <TimeField date={date} showTime={true} />;
  }

  const filters = [
    {
      name: "Email",
      filterType: "textField",
      options: [],
      defaultValue: "",
      param: "email",
    },
    {
      name: "Subscription Name",
      filterType: "dropDown",
      options: subscriptions?.result?.map((item) => {
        return { label: item.name, id: item._id };
      }),
      defaultValue: { label: "", id: "" },
      param: "subscriptionTypeId",
    },
  ];
  return (
    <>
      {getUserRoles.isEmployee ? (
        <div>
          <Link to="/customer-subscriptions/addCustomerSubscription">
            <div
              className={clsx(
                "addMemberButton",
                "buttonEffectShadow",
                "activeButton"
              )}
              style={{ width: "240px" }}
            >
              <ShoppingCart size={"20px"} />
              Add Customer Subscription
            </div>
          </Link>
        </div>
      ) : (
        <div>
          <Link to="/customer-subscriptions/addCustomerSubscription">
            <div
              className={clsx(
                "addMemberButton",
                "buttonEffectShadow",
                "activeButton"
              )}
              style={{ width: "240px", marginRight: 20 }}
            >
              <ShoppingCart size={"20px"} />
              Add Customer Subscription
            </div>
          </Link>
          <div
            className={clsx(
              "addMemberButton",
              "buttonEffectShadow",
              "activeButton"
            )}
            style={{ width: "240px" }}
            onClick={handleSendData}
          >
            Send Data
          </div>
          <Filter
            filters={filters}
            setQueryString={(query) => {
              setQueryString(query);
              setPage(1);
            }}
          />
          {!!!data?.result ? (
            <LinearLoading />
          ) : (
            <>
              <Table
                data={data?.result?.docs}
                dataOrder={[
                  "email",
                  "subscriptionName",
                  "subscriptionTypeId",
                  "amount",
                  "startDate",
                  "note",
                  "createdBy",
                ]}
                uniqueId={"_id"}
                enableDelete={true}
                apiEndpoint={"userSubscription"}
                setLimit={setLimit}
                setPage={setPage}
                count={data?.result?.totalDocs}
                currentPage={data?.result?.page}
                pagination={true}
              />
            </>
          )}
          <Toaster />
        </div>
      )}
    </>
  );
};

export default CustomerSubcriptions;
