import React, { useEffect, useState } from "react";
// import MultiSelect from "react-select";
import makeAnimated from "react-select/animated";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  TextField,
  Typography,
  //   Select,
  //   MenuItem,
  FormControl,
  //   InputLabel,
  Switch,
  FormGroup,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
// import Grid from "@material-ui/core/Grid";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import useSWR from "swr";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import QuillTextEditor from "../../components/Commons/QuillTextEditor";
import { Grid } from "react-feather";

interface AddEditPopUpProps {}

const animatedComponents = makeAnimated();

const AddEditPopUp: React.FC<AddEditPopUpProps> = ({}) => {
  const [openError, setOpenError] = useState(false);
  const [popUpData, setPopUpData] = useState({});
  const [content, setContent] = useState<string>("");

  let { id } = useParams();
  const navigate = useNavigate();

  // For POST Request
  const { data: postData, error: postError } = useSWR(
    !Boolean(id) ? [`/popUp`, `post`, popUpData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(postError) || !Boolean(postError?.status)) return;
    console.log(postError.info);
    setOpenError(true);
  }, [postError]);

  useEffect(() => {
    if (!Boolean(postData) || Boolean(postData?.error)) return;
    navigate("..", { replace: true });
  }, [postData]);

  //for GET Request to get popUp details. Depends on id parameter
  const { data: getData, error: getError } = useSWR(
    Boolean(id) ? `/popUp/${id}` : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(getError) || !Boolean(getError?.status)) return;
    console.log(getError.info);
    setOpenError(true);
  }, [getError]);

  //For PUT Request. Depends on the id param
  const { data: putData, error: putError } = useSWR(
    Boolean(id) ? [`/popUp/${id}`, `put`, popUpData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(putError) || !Boolean(putError?.status)) return;
    console.log(putError.info);
    setOpenError(true);
  }, [putError]);

  useEffect(() => {
    if (!Boolean(putData) || Boolean(putData?.error)) return;
    navigate("../..", { replace: true });
  }, [putData]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const setContentEditorData = (data: string) => {
    setContent(data);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: getData?.result?.name ?? "",
          isActive: getData?.result?.isActive ?? false,
          urls: getData?.result?.urls ?? "",
          type: getData?.result?.type ?? "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required("PopUp Name is required"),
          urls: Yup.string().required("Urls is required"),
        })}
        onSubmit={(values, actions) => {
          setPopUpData({ ...values, ...(Boolean(content) && { content }) });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              backgroundColor: "white",
              padding: "30px",
              borderRadius: "15px",
            }}
          >
            <Box>
              <Typography
                color="textPrimary"
                variant="h4"
                style={{ marginBottom: "20px" }}
              >
                {id ? "Edit PopUp:" : "Create a new PopUp:"}
              </Typography>
            </Box>
            <div className="form-container">
              <TextField
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="PopUp Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
                required
              />
              <TextField
                error={Boolean(touched.urls && errors.urls)}
                helperText={touched.urls && errors.urls}
                label="Urls (coma seperated)"
                margin="normal"
                name="urls"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.urls}
                variant="outlined"
                required
              />
              <Box>
                <FormControl
                  variant="outlined"
                  className="dropdown-form"
                  style={{ marginTop: "20px" }}
                >
                  <InputLabel>Type</InputLabel>
                  <Select
                    onChange={handleChange}
                    label="Type"
                    name="type"
                    value={values.type}
                  >
                    <MenuItem value={"FORM"}>FORM</MenuItem>
                    <MenuItem value={"NORMAL"}>NORMAL</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControl component="fieldset" style={{ marginTop: "20px" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isActive}
                          onChange={handleChange}
                          name="isActive"
                        />
                      }
                      label="Is Active"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box style={{ marginTop: "50px" }}>
                <h4 style={{ marginBottom: "10px" }}>Content:</h4>
                <QuillTextEditor
                  setHtmlData={setContentEditorData}
                  htmlData={getData?.result?.content}
                />
              </Box>
            </div>
            <Box style={{ marginTop: "50px" }}>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{ marginRight: "20px" }}
              >
                {id ? "Update PopUp" : "Create PopUp"}
              </Button>
              <Link to="/popUps">
                <Button
                  size="large"
                  variant="contained"
                  className={clsx(
                    "passiveButton",
                    "cancelButton",
                    "passiveButtonHover"
                  )}
                >
                  Cancel
                </Button>
              </Link>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error. Something went wrong.!
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default AddEditPopUp;
