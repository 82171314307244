import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import "../../assets/style/Notes.css";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import useSWR from "swr";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import Papa from "papaparse";
import _ from "lodash";

interface AddEditCustomerSubscriptionProps {}

const animatedComponents = makeAnimated();

const AddEditCustomerSubscription: React.FC<
  AddEditCustomerSubscriptionProps
> = ({}) => {
  const [openError, setOpenError] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [customerSubscription, setCustomerSubscription] = useState({});

  let { id } = useParams();
  const navigate = useNavigate();

  //For GET Request to get all the Sunscriptions
  const { data: subscriptionsData, error: subscriptionsError } = useSWR(
    `/userSubscription/subscriptionType`,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(subscriptionsData) || Boolean(subscriptionsData?.error))
      return;
    const dropdownData = [];
    subscriptionsData?.result?.forEach((item) => {
      let option = { value: item._id, label: item.name };
      dropdownData.push(option);
    });
    setSubscriptions(dropdownData);
  }, [subscriptionsData]);

  useEffect(() => {
    if (!Boolean(subscriptionsError) || !Boolean(subscriptionsError?.status))
      return;
    console.log(subscriptionsError.info);
    setOpenError(true);
  }, [subscriptionsError]);

  // For POST Request
  const { data: postData, error: postError } = useSWR(
    !Boolean(id) ? [`/userSubscription`, `post`, customerSubscription] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(postError) || !Boolean(postError?.status)) return;
    console.log(postError.info);
    setOpenError(true);
  }, [postError]);

  useEffect(() => {
    if (!Boolean(postData) || Boolean(postData?.error)) return;
    navigate("..", { replace: true });
  }, [postData]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          email: "",
          amount: null,
          note: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().required("Email cannot be empty"),
          amount: Yup.number().required("Enter amount"),
        })}
        onSubmit={(values, actions) => {
          let emails: any = [];
          emails = Papa.parse(values.email)?.data[0];

          setCustomerSubscription({
            email: _.uniq(emails),
            subscriptionTypeId: selectedOption.value,
            amount: values.amount,
            note: values.note,
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              backgroundColor: "white",
              padding: "30px",
              borderRadius: "15px",
            }}
          >
            <Box>
              <Typography
                color="textPrimary"
                variant="h4"
                style={{ marginBottom: "20px" }}
              >
                {id
                  ? "Edit subscription to customer(s):"
                  : "Add subscription to customer(s):"}
              </Typography>
            </Box>
            <div className="form-container">
              <TextField
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                label="Email(s)"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                variant="outlined"
                required
              />
              <Box style={{ marginTop: "20px", cursor: "pointer", zIndex: 10 }}>
                {Boolean(selectedSubscriptions) && (
                  <Select
                    options={subscriptions}
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    isClearable={true}
                    closeMenuOnSelect={true}
                    placeholder="Select Subscriptions"
                    components={animatedComponents}
                    onChange={setSelectedOption}
                    defaultValue={selectedSubscriptions}
                  />
                )}
                {!Boolean(selectedSubscriptions) && (
                  <Select
                    options={subscriptions}
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    isClearable={true}
                    closeMenuOnSelect={true}
                    placeholder="Select Subscriptions"
                    components={animatedComponents}
                    onChange={setSelectedOption}
                  />
                )}
              </Box>
              <TextField
                error={Boolean(touched.amount && errors.amount)}
                helperText={touched.amount}
                label="Amount"
                margin="normal"
                name="amount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.amount}
                variant="outlined"
                type="number"
                required
              />
              <TextField
                error={Boolean(touched.note && errors.note)}
                helperText={touched.note && errors.note}
                label="Note"
                margin="normal"
                name="note"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.note}
                variant="outlined"
              />
            </div>
            <Box style={{ marginTop: "50px" }}>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{ marginRight: "20px" }}
              >
                {id
                  ? "Update Customer(s) Subscription"
                  : "Create Customer(s) Subscription"}
              </Button>
              <Link to="/customer-subscriptions">
                <Button
                  size="large"
                  variant="contained"
                  className={clsx(
                    "passiveButton",
                    "cancelButton",
                    "passiveButtonHover"
                  )}
                >
                  Cancel
                </Button>
              </Link>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error. Something went wrong.!
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default AddEditCustomerSubscription;
