import React, { useEffect, useState } from "react";
import MultiSelect from "react-select";
import makeAnimated from "react-select/animated";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import "../../assets/style/Notes.css";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import useSWR from "swr";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import { useParams } from "react-router-dom";

interface AddEditVideosLibraryProps {}

const animatedComponents = makeAnimated();

const AddEditVideosLibrary: React.FC<AddEditVideosLibraryProps> = ({}) => {
  const [videos, setVideos] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [videosLibraryData, setVideosLibraryData] = useState({});
  const [openError, setOpenError] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState(null);

  let { id } = useParams();
  const navigate = useNavigate();

  //For GET Request to get all the notes
  const { data: videosData, error: videosError } = useSWR(
    `/videos`,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(videosData) || Boolean(videosData?.error)) return;
    const dropdownData = [];
    videosData?.result?.forEach((item) => {
      let option = { value: item._id, label: item.name };
      dropdownData.push(option);
    });
    setVideos(dropdownData);
  }, [videosData]);

  useEffect(() => {
    if (!Boolean(videosError) || !Boolean(videosError?.status)) return;
    console.log(videosError.info);
    setOpenError(true);
  }, [videosError]);

  // For POST Request
  const { data: postData, error: postError } = useSWR(
    !Boolean(id) ? [`/videosLibrary`, `post`, videosLibraryData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(postData) || Boolean(postData?.error)) return;
    navigate("..", { replace: true });
  }, [postData]);

  useEffect(() => {
    if (!Boolean(postError) || !Boolean(postError?.status)) return;
    console.log(postError.info);
    setOpenError(true);
  }, [postError]);

  //for GET Request to get notes details. Depends on id parameter
  const { data: getData, error: getError } = useSWR(
    Boolean(id) ? `/videosLibrary/${id}?videos=true` : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(getError) || !Boolean(getError?.status)) return;
    console.log(getError.info);
    setOpenError(true);
  }, [getError]);

  useEffect(() => {
    if (!Boolean(getData) || Boolean(getData?.error)) return;
    let modifiedVideos = [];
    getData?.result?.videos?.forEach((item) => {
      const video = { value: item._id, label: item.name };
      modifiedVideos.push(video);
    });
    if (modifiedVideos.length) {
      setSelectedOption(modifiedVideos);
      setSelectedVideos(modifiedVideos);
    }
  }, [getData]);

  //For PUT Request. Depends on the id param
  const { data: putData, error: putError } = useSWR(
    Boolean(id) ? [`/videosLibrary/${id}`, `put`, videosLibraryData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(putError) || !Boolean(putError?.status)) return;
    console.log(putError.info);
    setOpenError(true);
  }, [putError]);

  useEffect(() => {
    if (!Boolean(putData) || Boolean(putData?.error)) return;
    navigate("../..", { replace: true });
  }, [putData]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: getData?.result?.name ?? "",
          description: getData?.result?.description ?? "",
          category: getData?.result?.category ?? "",
          type: getData?.result?.type ?? "",
          year: getData?.result?.year ?? "",
          isVisible: getData?.result?.isVisible ?? false,
          isActive: getData?.result?.isActive ?? false,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255)
            .required("Videos Library Name is required"),
          description: Yup.string().required("Description is required"),
        })}
        onSubmit={(values, actions) => {
          let videos = [];
          selectedOption?.forEach((item) => {
            videos.push(item.value);
          });
          setVideosLibraryData({ ...values, videos });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              backgroundColor: "white",
              padding: "30px",
              borderRadius: "15px",
            }}
          >
            <Box>
              <Typography
                color="textPrimary"
                variant="h4"
                style={{ marginBottom: "20px" }}
              >
                {id ? "Edit Videos Library:" : "Create a new Videos Library:"}
              </Typography>
            </Box>
            <div className="form-container">
              <TextField
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Videos Library Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
                required
              />
              <TextField
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                label="Description"
                multiline
                rows={2}
                rowsMax={4}
                margin="normal"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                value={values.description}
                required
              />
              <TextField
                label="Category"
                margin="normal"
                name="category"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.category}
                variant="outlined"
              />
              <Box style={{ marginTop: "20px", cursor: "pointer", zIndex: 10 }}>
                {Boolean(selectedVideos) && (
                  <MultiSelect
                    options={videos}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Videos"
                    components={animatedComponents}
                    onChange={setSelectedOption}
                    defaultValue={selectedVideos}
                  />
                )}
                {!Boolean(selectedVideos) && (
                  <MultiSelect
                    options={videos}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Videos"
                    components={animatedComponents}
                    onChange={setSelectedOption}
                  />
                )}
              </Box>
              <Box style={{ marginTop: "20px" }}>
                <FormControl
                  variant="outlined"
                  className="dropdown-form"
                  style={{ marginRight: "20px" }}
                >
                  <InputLabel>Type</InputLabel>
                  <Select
                    onChange={handleChange}
                    label="Type"
                    name="type"
                    value={values.type}
                  >
                    <MenuItem value="prelims">Prelims</MenuItem>
                    <MenuItem value="mains">Mains</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className="dropdown-form">
                  <InputLabel>Year</InputLabel>
                  <Select
                    onChange={handleChange}
                    label="Year"
                    name="year"
                    value={values.year}
                  >
                    <MenuItem value={2021}>2021</MenuItem>
                    <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControl component="fieldset" style={{ marginTop: "20px" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isVisible}
                          onChange={handleChange}
                          name="isVisible"
                        />
                      }
                      label="Is Visible"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isActive}
                          onChange={handleChange}
                          name="isActive"
                        />
                      }
                      label="Is Active"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            </div>
            <Box style={{ marginTop: "50px" }}>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{ marginRight: "20px" }}
              >
                {id ? "Update Videos" : "Create Videos"}
              </Button>
              <Link to="/videosLibrary">
                <Button
                  size="large"
                  variant="contained"
                  className={clsx(
                    "passiveButton",
                    "cancelButton",
                    "passiveButtonHover"
                  )}
                >
                  Cancel
                </Button>
              </Link>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error. Something went wrong.!
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default AddEditVideosLibrary;
