import React, { useEffect, useMemo, useRef, useState } from "react";
import useSWR, { mutate } from "swr";
import { fetcher } from "../../utils/Functions";
import { staticOptions, AWS_URL } from "../../utils/Constants";
import Table from "../../components/Commons/Table";
import LinearLoading from "../../components/Commons/LinearLoading";
import FileUploader from "../../components/Commons/FileUploader";
import TimeField from "../../components/Commons/TimeField";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import LaunchIcon from "@material-ui/icons/Launch";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { X as XIcon } from "react-feather";
import clsx from "clsx";
import CustomInput from "../../components/Commons/CustomInput";
import { Autocomplete } from "@material-ui/lab";
import Filter from "../../components/Commons/Filter";

interface AnswersProps {}

const Answers: React.FC<AnswersProps> = ({}) => {
  const [openError, setOpenError] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [scoreGiven, setScore] = useState<any>({});
  const [noOfEssays, setNoOfEssays] = useState<any>({});
  const [noOfQuestions, setNoOfQuestions] = useState<any>({});
  const [evaluator, setEvaluator] = useState<any>({});
  const [evaluatedPdfUrlData, setEvaluatedPdfUrlData] = useState<any>({});
  const [IsRejected, setIsRejected] = useState<any>({});
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);

  const [queryString, setQueryString] = useState<string>(``);

  const [open, setOpen] = useState<boolean>(false);
  const [snackBar, setSnackBar] = useState({ open: false, message: "" });
  const selectedAnswer = useRef({ Id: "", uid: "", questionName: "" });
  let reason: string;
  let questionSchema = Yup.object().shape({
    number: Yup.number()
      .max(100)
      .min(0)
      .required("Number of questions is required")
      .typeError("Number of questions is required"),
  });
  let essaySchema = Yup.object().shape({
    number: Yup.number()
      .max(100)
      .min(0)
      .required("Number of essays is required")
      .typeError("Number of essays is required"),
  });

  var expression =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  var regex = new RegExp(expression);

  const { data, error, isValidating } = useSWR(
    `/mainsTS/answers/?limit=${limit}&page=${page}${queryString}`,
    fetcher,
    staticOptions
  );
  const { data: evaluatorsList } = useSWR(
    `/users/answers?role=EVALUATOR`,
    fetcher,
    staticOptions
  );

  const { data: questions, error: questionError } = useSWR(
    `mainsTS/questions`,
    fetcher,
    staticOptions
  );
  const { data: mainsTests, error: mainsTestsError } = useSWR(
    `mainsTS`,
    fetcher,
    staticOptions
  );

  const { data: putScoreData, error: postScoreError } = useSWR(
    [`/mainsTS/answers/` + scoreGiven.answerId, `put`, scoreGiven],
    fetcher,
    staticOptions
  );

  const { data: putNoOfEssaysData, error: postNoOfEssaysError } = useSWR(
    [`/mainsTS/answers/` + noOfEssays.answerId, `put`, noOfEssays],
    fetcher,
    staticOptions
  );

  const { data: putNoOfQuestionaData, error: postNoOfQuestionsError } = useSWR(
    [`/mainsTS/answers/` + noOfQuestions.answerId, `put`, noOfQuestions],
    fetcher,
    staticOptions
  );

  const { data: putData, error: postError } = useSWR(
    [`/mainsTS/answers/` + evaluator.answerId, `put`, evaluator],
    fetcher,
    staticOptions
  );

  const { data: putEvaluatedPdfData, error: putEvaluatedPdfError } = useSWR(
    [
      `/mainsTS/answers/` + evaluatedPdfUrlData.answerId,
      `put`,
      evaluatedPdfUrlData,
    ],
    fetcher,
    staticOptions
  );

  const { data: putIsRejectedData, error: putIsRejectedError } = useSWR(
    [`/mainsTS/answers/` + IsRejected.answerId, `put`, IsRejected],
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    setOpenError(true);
  }, [error]);

  useEffect(() => {
    if (!Boolean(putEvaluatedPdfData) || Boolean(putEvaluatedPdfData?.error))
      return;
    setSnackBar({
      open: true,
      message: "Evaluated copy submitted successfully",
    });
    mutate(`/mainsTS/answers/?limit=${limit}&page=${page}${queryString}`);
  }, [putEvaluatedPdfData]);

  useEffect(() => {
    if (!Boolean(putData) || Boolean(putData?.error)) return;
    setSnackBar({ open: true, message: "Evaluator assigned successfully" });
    mutate(`/mainsTS/answers/?limit=${limit}&page=${page}${queryString}`);
  }, [putData]);

  useEffect(() => {
    if (!Boolean(putScoreData) || Boolean(putScoreData?.error)) return;
    setSnackBar({ open: true, message: "Score Updated" });
    mutate(`/mainsTS/answers/?limit=${limit}&page=${page}${queryString}`);
  }, [putScoreData]);

  useEffect(() => {
    if (!Boolean(putNoOfEssaysData) || Boolean(putNoOfEssaysData?.error))
      return;
    setSnackBar({ open: true, message: "Number of Essays Updated" });
    mutate(`/mainsTS/answers/?limit=${limit}&page=${page}${queryString}`);
  }, [putNoOfEssaysData]);

  useEffect(() => {
    if (!Boolean(putNoOfQuestionaData) || Boolean(putNoOfQuestionaData?.error))
      return;
    setSnackBar({ open: true, message: "Number of Questions Updated" });
    mutate(`/mainsTS/answers/?limit=${limit}&page=${page}${queryString}`);
  }, [putNoOfQuestionaData]);

  useEffect(() => {
    if (!Boolean(putIsRejectedData) || Boolean(putIsRejectedData?.error))
      return;
    setSnackBar({ open: true, message: "Answer Rejected" });
    mutate(`/mainsTS/answers/?limit=${limit}&page=${page}${queryString}`);
  }, [putIsRejectedData]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  useEffect(() => {
    if (
      !Boolean(data) ||
      Boolean(data?.error) ||
      !Boolean(evaluatorsList) ||
      Boolean(evaluatorsList?.error)
    )
      return;
    const CompData = JSON.parse(
      JSON.stringify(data?.result?.mainsTSAnswersList?.docs)
    );

    if (CompData?.length > 0) {
      CompData?.forEach((item, index) => {
        item.answerPdfUrl =
          Boolean(item.answerPdfUrl) && getLinkIcon(item.answerPdfUrl);
        item.evaluatedPdfUrl = uploadEvaluatedCopy(
          item.evaluatedPdfUrl,
          item._id,
          item.status
        );
        if (item.evaluatorId) {
          item.evaluatorId = assignEvaluator(item._id, item.evaluatorId);
        } else {
          item.evaluatorId = assignEvaluator(item._id);
        }
        if (item.status !== "EVALUATED") {
          item.reject = rejectAnswer(
            item._id,
            item.status,
            item.rejectReason,
            item.uid,
            item.questionDetails.name
          );
        }
        item.score = addScore(
          item._id,
          item.score,
          item?.questionDetails?.maxScore || 5
        );
        item.noOfEssays = addNoOfessays(item._id, item.noOfEssays);
        item.noOfQuestions = addNoOfQuestions(item._id, item.noOfQuestions);
        item["questionName"] = item.questionDetails?.name;
        delete CompData[index]?.questionDetails;
        item.createdAt = timeField(item.createdAt);
      });
    }
    setTableData(CompData);
  }, [evaluatorsList, data]);

  function timeField(date: string) {
    return <TimeField date={date} showTime={true} />;
  }

  function assignEvaluator(answerId: string, evaluatorid?: string) {
    let defaultName = "";
    if (evaluatorid) {
      defaultName = evaluatorsList?.result?.filter(
        (item) => item._id == evaluatorid
      )[0]?.name;
    }

    return (
      Boolean(data?.result?.mainsTSAnswersList?.docs) &&
      Boolean(evaluatorsList?.result) && (
        <>
          <Autocomplete
            style={{ width: 150 }}
            disableClearable
            options={evaluatorsList?.result}
            getOptionLabel={(item) => item.name}
            renderInput={(params) => {
              return <TextField {...params} label="Evaluators" />;
            }}
            defaultValue={{ name: defaultName }}
            onChange={(e, value: any, reason) => {
              if (value) {
                setEvaluator({
                  answerId: answerId,
                  evaluatorId: value._id,
                });
              }
            }}
          />
        </>
      )
    );
  }

  function getLinkIcon(url: string) {
    if (!url?.match(regex)) {
      url = `${AWS_URL}${url}`;
    }
    return (
      <Tooltip title="Open File" arrow>
        <IconButton
          aria-label="open"
          onClick={() => window.open(`${url}`, "_blank")}
        >
          <LaunchIcon color="action" />
        </IconButton>
      </Tooltip>
    );
  }

  function uploadEvaluatedCopy(url: string, answerid: string, status: string) {
    let uploadedPDFUrl: string;
    function setURL(url: string) {
      uploadedPDFUrl = url;
    }
    return (
      <div>
        {url ? (
          <div>
            <Tooltip title="Open File" arrow>
              <IconButton
                aria-label="open"
                onClick={() => window.open(`${AWS_URL}${url}`, "_blank")}
              >
                <LaunchIcon color="action" />
              </IconButton>
            </Tooltip>
            {status == "EVALUATED" && (
              <div>
                <FileUploader setFileUrl={setURL} />
                <Button
                  color="primary"
                  size="small"
                  type="submit"
                  variant="contained"
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    setEvaluatedPdfUrlData({
                      answerId: answerid,
                      evaluatedPdfUrl: uploadedPDFUrl,
                    });
                  }}
                >
                  Reupload
                </Button>
              </div>
            )}
          </div>
        ) : (
          status == "UNDER_EVALUATION" && (
            <div>
              <FileUploader setFileUrl={setURL} />
              <Button
                color="primary"
                size="small"
                type="submit"
                variant="contained"
                style={{ marginRight: "20px" }}
                onClick={() => {
                  setEvaluatedPdfUrlData({
                    answerId: answerid,
                    evaluatedPdfUrl: uploadedPDFUrl,
                  });
                }}
              >
                Submit
              </Button>
            </div>
          )
        )}
      </div>
    );
  }

  function rejectAnswer(
    answerId: string,
    status: string,
    rejectReason: string,
    uid: string,
    questionName: string
  ) {
    return (
      <>
        {!Boolean(rejectReason) && !(status === "REJECTED") && (
          <XIcon
            style={{ color: "red", cursor: "pointer" }}
            width={30}
            height={30}
            onClick={() => {
              selectedAnswer.current.Id = answerId;
              selectedAnswer.current.uid = uid;
              selectedAnswer.current.questionName = questionName;
              handleClickOpen();
            }}
          />
        )}
        {Boolean(rejectReason) && <p>{rejectReason}</p>}
      </>
    );
  }

  function addScore(answerId: string, initialScore: number, maxScore: number) {
    let scoreSchema = Yup.object().shape({
      number: Yup.number()
        .max(maxScore)
        .min(0)
        .required("Score is required")
        .typeError("Score is required"),
    });

    return (
      <div>
        <CustomInput
          label="Score"
          data={{ answerId, initialNumber: initialScore }}
          valueKey="score"
          validationSchema={scoreSchema}
          handleSubmit={setScore}
        />
      </div>
    );
  }

  function addNoOfessays(answerId: string, initialNoOfEssays?: number) {
    return (
      <div>
        <CustomInput
          label="No Of Essays"
          data={{ answerId, initialNumber: initialNoOfEssays }}
          valueKey="noOfEssays"
          validationSchema={essaySchema}
          handleSubmit={setNoOfEssays}
        />
      </div>
    );
  }

  function addNoOfQuestions(answerId: string, initialNoOfQuestions?: number) {
    return (
      <div>
        <CustomInput
          label="No Of Questions"
          data={{ answerId, initialNumber: initialNoOfQuestions }}
          valueKey="noOfQuestions"
          validationSchema={questionSchema}
          handleSubmit={setNoOfQuestions}
        />
      </div>
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleReject = () => {
    if (Boolean(reason)) {
      setIsRejected({
        answerId: selectedAnswer.current.Id,
        reject: "True",
        rejectReason: reason,
      });
      selectedAnswer.current = { Id: "", uid: "", questionName: "" };
      handleClose();
    }
    return;
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({ open: false, message: "" });
  };

  const filters = [
    {
      name: "UserId",
      filterType: "textField",
      options: [],
      defaultValue: "",
      param: "userId",
    },
    {
      name: "Uid",
      filterType: "textField",
      options: [],
      defaultValue: "",
      param: "uid",
    },
    {
      name: "Evaluator",
      filterType: "dropDown",
      options: evaluatorsList?.result?.map((item) => {
        return { label: item.name, id: item._id };
      }),
      defaultValue: { label: "", id: "" },
      param: "evaluatorId",
    },
    {
      name: "Question",
      filterType: "dropDown",
      options: questions?.result?.mainsTSQuestionsList?.map((item) => {
        return { label: item.name, id: item._id };
      }),
      defaultValue: { label: "", id: "" },
      param: "questionId",
    },
    {
      name: "Mains Test",
      filterType: "dropDown",
      options: mainsTests?.result?.mainsTSList?.map((item) => {
        return { label: item.name, id: item._id };
      }),
      defaultValue: { label: "", id: "" },
      param: "mainsTSId",
    },
    {
      name: "Evaluation Status",
      filterType: "dropDown",
      options: [
        { label: "REJECTED", id: "REJECTED" },
        { label: "UNDER_EVALUATION", id: "UNDER_EVALUATION" },
        { label: "EVALUATED", id: "EVALUATED" },
      ],
      defaultValue: { label: "", id: "" },
      param: "status",
    },
    {
      name: "Assigned Status",
      filterType: "dropDown",
      options: [
        { label: "Yes", id: "Yes" },
        { label: "No", id: "No" },
      ],
      defaultValue: { label: "", id: "" },
      param: "isAssigned",
    },
  ];
  return (
    <div>
      <Filter
        filters={filters}
        setQueryString={(query) => {
          setQueryString(query);
          setPage(1);
        }}
      />
      {Boolean(data?.result) && Boolean(tableData) && !isValidating ? (
        <Table
          data={tableData}
          dataOrder={[
            "userId",
            "uid",
            "questionName",
            "mainsName",
            "answerPdfUrl",
            "reject",
            "evaluatorId",
            "status",
            "evaluatedPdfUrl",
            "noOfQuestions",
            "noOfEssays",
            "score",
            "createdAt",
          ]}
          pagination={true}
          setLimit={setLimit}
          setPage={setPage}
          count={data?.result?.mainsTSAnswersList?.totalDocs}
          currentPage={data?.result?.mainsTSAnswersList?.page}
          enableDelete={true}
          uniqueId={"_id"}
          apiEndpoint={"mainsTS/answers"}
          mutateKey={`mainsTS/answers/?limit=${limit}&page=${page}${queryString}`}
        />
      ) : (
        <LinearLoading />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reject Reason</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the reason for rejecting the answer of{" "}
            <span style={{ fontWeight: "bold" }}>
              Uid: {selectedAnswer.current.uid}{" "}
            </span>
            for the{" "}
            <span style={{ fontWeight: "bold" }}>
              question: {selectedAnswer.current.questionName}
            </span>{" "}
            (max 30 characters)
          </DialogContentText>
          <TextField
            required
            multiline
            fullWidth
            inputProps={{ maxLength: 30 }}
            style={{ width: "100%" }}
            margin="dense"
            label="Enter Reason"
            type="name"
            variant="outlined"
            className="nameField"
            onChange={(e) => {
              reason = e.target.value;
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className={clsx(
              "passiveButton",
              "cancelButton",
              "passiveButtonHover"
            )}
          >
            Cancel
          </Button>
          <Button
            onClick={handleReject}
            className={clsx("activeButton", "addButton", "activeButtonHover")}
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while fetching Notes.
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={1000}
        onClose={handleMessageClose}
      >
        <Alert onClose={handleMessageClose} severity="success">
          {snackBar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default Answers;
